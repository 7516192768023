import React, { Component } from 'react';
import './index.scss';

import banner from '../../../../assets/app/about/amr/banner.png'
import func from '../../../../assets/app/about/amr/func.png'
import palce from '../../../../assets/app/about/amr/palce.png'
import prority from '../../../../assets/app/about/amr/prority.png'
import client from '../../../../assets/app/about/amr/client.png'
import device from '../../../../assets/app/about/amr/device.png'




class AMR extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){
  }

  render() {
    return (
      <div className="imageBlock">
          {/* <p style={{paddingLeft:'5%',fontSize:24,paddingTop:10 }}>详情页</p> */}
          <img src={banner} className="backgroundbg"/>
          <img src={func} className="backgroundbg"/>
          <img src={palce} className="backgroundbg"/>
          <img src={prority} className="backgroundbg"/>    
          <img src={client} className="backgroundbg"/>
          <img src={device} className="backgroundbg"/>
      </div>
    );
  }
}

export default AMR;
