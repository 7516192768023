import React, { Component } from 'react';
import './index.scss';
import gw200pic1 from '../../../../../assets/product/gateway/gw200/gw200-1.png'
import gw200pic2 from '../../../../../assets/product/gateway/gw200/gw200-2.png'
import gw200pic3 from '../../../../../assets/product/gateway/gw200/gw200-3.png'
import gw200pic4 from '../../../../../assets/product/gateway/gw200/gw200-4.png'
import gw200pic5 from '../../../../../assets/product/gateway/gw200/gw200-5.png'
import gw200pic6 from '../../../../../assets/product/gateway/gw200/gw200-6.png'
import gw200pic7 from '../../../../../assets/product/gateway/gw200/gw200-7.png'



class GatewayGw200 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='网关'
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={gw200pic1}/>
          <img src={gw200pic2}/>
          <img src={gw200pic3}/>
          <img src={gw200pic4}/>
          <img src={gw200pic5}/>
          <img src={gw200pic6}/>
          <img src={gw200pic7}/>
      </div>
    );
  }

}

export default GatewayGw200;
