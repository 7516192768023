import React, { createContext } from 'react'

export const IndexBarContext = createContext<{
  indexes: string[];
  setIndexes: React.Dispatch<React.SetStateAction<string[]>>;
  groups: any[];
  setGroups: React.Dispatch<React.SetStateAction<any[]>>;
}>({
  indexes: [],
  setIndexes: () => {},
  groups: [],
  setGroups: () => {},
})
