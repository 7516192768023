import React, { Component } from 'react';
import './index.scss';
import r200pic1 from '../../../../../assets/product/heatmeter/r200/r200-1.png'
import r200pic2 from '../../../../../assets/product/heatmeter/r200/r200-2.png'
import r200pic3 from '../../../../../assets/product/heatmeter/r200/r200-3.png'
import r200pic4 from '../../../../../assets/product/heatmeter/r200/r200-4.png'
import r200pic5 from '../../../../../assets/product/heatmeter/r200/r200-5.png'
import r200pic6 from '../../../../../assets/product/heatmeter/r200/r200-6.png'
import r200pic7 from '../../../../../assets/product/heatmeter/r200/r200-7.png'



class HeatR200 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='热表'
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={r200pic1}/>
          <img src={r200pic2}/>
          <img src={r200pic3}/>
          <img src={r200pic4}/>
          <img src={r200pic5}/>
          <img src={r200pic6}/>
          <img src={r200pic7}/>
      </div>
    );
  }

}

export default HeatR200;
