import React, { Component } from 'react';
import './index.scss';
import ct213pic1 from '../../../../../assets/product/gateway/ct213/ct213-1.png'
import ct213pic2 from '../../../../../assets/product/gateway/ct213/ct213-2.png'
import ct213pic3 from '../../../../../assets/product/gateway/ct213/ct213-3.png'
import ct213pic4 from '../../../../../assets/product/gateway/ct213/ct213-4.png'
import ct213pic5 from '../../../../../assets/product/gateway/ct213/ct213-5.png'
import ct213pic6 from '../../../../../assets/product/gateway/ct213/ct213-6.png'
import ct213pic7 from '../../../../../assets/product/gateway/ct213/ct213-7.png'
import ct213pic8 from '../../../../../assets/product/gateway/ct213/ct213-8.png'



class GatewayCT213 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='网关'
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={ct213pic1}/>
          <img src={ct213pic2}/>
          <img src={ct213pic3}/>
          <img src={ct213pic4}/>
          <img src={ct213pic5}/>
          <img src={ct213pic6}/>
          <img src={ct213pic7}/>
          <img src={ct213pic8}/>
      </div>
    );
  }

}

export default GatewayCT213;
