import React, { Component } from 'react';
import './index.scss';
import waterpic1 from '../../../../assets/solution/water/water-1.png'
import waterpic2 from '../../../../assets/solution/water/water-2.png'
import waterpic3 from '../../../../assets/solution/water/water-3.png'
import waterpic4 from '../../../../assets/solution/water/water-4.png'
import waterpic5 from '../../../../assets/solution/water/water-5.png'
import waterpic6 from '../../../../assets/solution/water/water-6.png'
import waterpic7 from '../../../../assets/solution/water/water-7.png'

import { ListView } from 'antd-mobile';


class SolutionWater extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='水计量方案'
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={waterpic1}/>
          <img src={waterpic2}/>
          <img src={waterpic3}/>
          <img src={waterpic4}/>
          <img src={waterpic5}/>
          <img src={waterpic6}/>
          <img src={waterpic7}/>
      </div>
    );
  }

}

export default SolutionWater;
