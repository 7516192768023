import React, { Component } from 'react';
import './index.scss';
import ddsu5168pic1 from '../../../../../assets/product/electricmeter/ddsu5168/ddsu5168-1.png'
import ddsu5168pic2 from '../../../../../assets/product/electricmeter/ddsu5168/ddsu5168-2.png'
import ddsu5168pic3 from '../../../../../assets/product/electricmeter/ddsu5168/ddsu5168-3.png'
import ddsu5168pic4 from '../../../../../assets/product/electricmeter/ddsu5168/ddsu5168-4.png'
import ddsu5168pic5 from '../../../../../assets/product/electricmeter/ddsu5168/ddsu5168-5.png'
import ddsu5168pic6 from '../../../../../assets/product/electricmeter/ddsu5168/ddsu5168-6.png'
import ddsu5168pic7 from '../../../../../assets/product/electricmeter/ddsu5168/ddsu5168-7.png'
import ddsu5168pic8 from '../../../../../assets/product/electricmeter/ddsu5168/ddsu5168-8.png'

import {Link} from 'react-router-dom'



class ElectricDdsu5168 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsmeter:''
    };
  }
  
  
  componentDidMount(){
    document.title='电表'
    setTimeout(()=> {
      this.setState({
        paramsmeter:'电能表参数>>'
      })
    },1000)
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={ddsu5168pic1}/>
          <img src={ddsu5168pic2}/>
          <img src={ddsu5168pic3}/>
          <img src={ddsu5168pic4}/>
          <img src={ddsu5168pic5}/>
          <img src={ddsu5168pic6}/>
          <img src={ddsu5168pic7}/>
          <div className="bottomLink-ddsu5168">
          <img src={ddsu5168pic8}/>
          <Link to='/elecmeter/para'><div>{this.state.paramsmeter}</div></Link>
          </div>
    

      </div>
    );
  }

}

export default ElectricDdsu5168;
