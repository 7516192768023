import React, { FC, useState } from 'react'
import classNames from 'classnames'

const classPrefix = `adm-index-bar`

type SidebarProps = {
  groups: any[]
  indexes: string[]
  activeIndex: string
  onActive: (index: string) => void
}

export const Sidebar: FC<SidebarProps> = props => {
  const [interacting, setInteracting] = useState(false)
  
  return (
    <div
      className={classNames(`${classPrefix}-sidebar`, {
        [`${classPrefix}-sidebar-interacting`]: interacting,
      })}
      onMouseDown={() => {
        setInteracting(true)
      }}
      onMouseUp={() => {
        setInteracting(false)
      }}
      onTouchStart={() => {
        setInteracting(true)
      }}
      onTouchEnd={() => {
        setInteracting(false)
      }}
      // onTouchMove={e => {
      //   if (!interacting) return
      //   const { clientX, clientY } = e.touches[0]
      //   const target = document.elementFromPoint(
      //     clientX,
      //     clientY
      //   ) as HTMLElement
      //   if (!target) return
      //   const index = target.dataset['index']
      //   if (index) {
      //     props.onActive(index)
      //   }
      // }}
    >
      {props.groups.map((item: any) => {
        const active = item.categoryId == props.activeIndex
        return (
          <div
            className={`${classPrefix}-sidebar-row`}
            onMouseDown={() => {
              props.onActive(item.categoryId)
            }}
            onTouchStart={() => {
              props.onActive(item.categoryId)
            }}
            onMouseEnter={() => {
              if (interacting) {
                props.onActive(item.categoryId)
              }
            }}
            data-index={item.categoryId}
            key={item.categoryId}
          >
            {/* {interacting && active && (
              <div className={`${classPrefix}-sidebar-bubble`}>{index}</div>
            )} */}
            <div
              className={classNames(`${classPrefix}-sidebar-item`, {
                [`${classPrefix}-sidebar-item-active`]: active,
              })}
              data-index={item.categoryId}
            >
              {/* 侧边栏 */}              
              <div className="adm-index-bar-border border-top"></div>
              <div>
                <span>{`${item.categoryName}（${item.items.length}）`}</span>
                {item.description && (<><br/><div style={{ marginTop: 4, fontSize: 10, color: 'grey', fontWeight: 400 }}>{item.description}</div></>)}
              </div>              
              <div className="adm-index-bar-border border-bottom" ></div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
