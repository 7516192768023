import React, { Component } from 'react';
import './index.scss';
import dtsd5168pic1 from '../../../../../assets/product/electricmeter/dtsd5168/dtsd5168-1.png'
import dtsd5168pic2 from '../../../../../assets/product/electricmeter/dtsd5168/dtsd5168-2.png'
import dtsd5168pic3 from '../../../../../assets/product/electricmeter/dtsd5168/dtsd5168-3.png'
import dtsd5168pic4 from '../../../../../assets/product/electricmeter/dtsd5168/dtsd5168-4.png'
import dtsd5168pic5 from '../../../../../assets/product/electricmeter/dtsd5168/dtsd5168-5.png'
import dtsd5168pic6 from '../../../../../assets/product/electricmeter/dtsd5168/dtsd5168-6.png'
import dtsd5168pic7 from '../../../../../assets/product/electricmeter/dtsd5168/dtsd5168-7.png'

import {Link} from 'react-router-dom'


class ElectricDtsd5168 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsmeter:''
    };
  }
  
  
  componentDidMount(){
    document.title='电表'
    setTimeout(()=> {
      this.setState({
        paramsmeter:'电能表参数>>'
      })
    },1000)
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={dtsd5168pic1}/>
          <img src={dtsd5168pic2}/>
          <img src={dtsd5168pic3}/>
          <img src={dtsd5168pic4}/>
          <img src={dtsd5168pic5}/>
          <img src={dtsd5168pic6}/>
          <div className="bottomLink-dtsd5168">
          <img src={dtsd5168pic7}/>
          <Link to='/elecmeter/para'><div>{this.state.paramsmeter}</div></Link>
          </div>
      </div>
    );
  }

}

export default ElectricDtsd5168;
