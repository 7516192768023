import React, { Component } from 'react';
import './index.scss';
import heatpic1 from '../../../../assets/solution/heat/heat-1.png'
import heatpic2 from '../../../../assets/solution/heat/heat-2.png'
import heatpic3 from '../../../../assets/solution/heat/heat-3.png'
import heatpic4 from '../../../../assets/solution/heat/heat-4.png'
import heatpic5 from '../../../../assets/solution/heat/heat-5.png'
import heatpic6 from '../../../../assets/solution/heat/heat-6.png'
import heatpic7 from '../../../../assets/solution/heat/heat-7.png'
import heatpic8 from '../../../../assets/solution/heat/heat-8.png'

import { ListView } from 'antd-mobile';


class SolutionHeat extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='热计量方案'
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={heatpic1}/>
          <img src={heatpic2}/>
          <img src={heatpic3}/>
          <img src={heatpic4}/>
          <img src={heatpic5}/>
          <img src={heatpic6}/>
          <img src={heatpic7}/>
          <img src={heatpic8}/>
      </div>
    );
  }

}

export default SolutionHeat;
