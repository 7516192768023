import React, { Component } from 'react';
import style from './details.scss';
import { ImageViewer } from 'antd-mobile-v5';
import Axios from 'axios';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
        images:[],
        visible:false
    };
  }
  formatData = (bodyData) => {
    let formData = new FormData();
    for (const key in bodyData) {
      if (bodyData.hasOwnProperty(key)) {
        const element = bodyData[key];
        if (element == 0) formData.append(key, element);
        else if (element) formData.append(key, element);
      }
    }
    return formData;
  }
  componentDidMount(){
    document.title='产品说明'
    let code = this.getQueryVariable("code");
    const values = {
      instructionsNo: code
    };
    Axios({
      url:'http://spt.joymeter.com/spt/instructions/scanFile',
      method:'POST',
      data:this.formatData(values)
    }).then(res => {
        if (res.data.status == 1) {
          this.setState({
            visible:true,
            images: res.data.object //浏览者备用
          });
        } else {
          this.setState({
            visible:false
          });
        }          
    }).catch(error => {
        throw new Error(error)
    })
  }
  getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
    }
    return(false);
  }
  render() {
    return (
      <div className='adm-mask'>
       <ImageViewer.Multi
        images={this.state.images}
        visible={this.state.visible}
        defaultIndex={0}
      /> 
    </div>
    );
  }

}

export default  Details;
