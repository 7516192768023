import React, { Component } from 'react';
import './index.scss';
import mecpic1 from '../../../../../assets/product/watermeter/mec/mec-1.png'
import mecpic2 from '../../../../../assets/product/watermeter/mec/mec-2.png'
import mecpic3 from '../../../../../assets/product/watermeter/mec/mec-3.png'
import mecpic4 from '../../../../../assets/product/watermeter/mec/mec-4.png'
import mecpic5 from '../../../../../assets/product/watermeter/mec/mec-5.png'
import mecpic6 from '../../../../../assets/product/watermeter/mec/mec-6.png'
import mecpic7 from '../../../../../assets/product/watermeter/mec/mec-7.png'

import {Link} from 'react-router-dom'


class WaterMec extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsmeter:''
    };
  }
  
  componentDidMount(){
    document.title='水表'
    setTimeout(()=> {
      this.setState({
        paramsmeter:'水表参数>>'
      })
    },1000)
  }

  render() {
    return (
      
      <div className="imageBlock">
         <div>
            <img src={mecpic1}/>
            <img src={mecpic2}/>
            <img src={mecpic3}/>
            <img src={mecpic4}/>
            <img src={mecpic5}/>
            <img src={mecpic6}/>
          </div>

          <div  className="bottomLink-mec">
              <img src={mecpic7}/>
              <Link to='/watermeter/para'>
                <div>{this.state.paramsmeter}</div>
              </Link>
          </div>
      </div>
      
    );
  }

}

export default WaterMec;
