import React, { Component } from 'react';
import './index.scss';
import elecpic1 from '../../../../assets/solution/elec/elec-1.png'
import elecpic2 from '../../../../assets/solution/elec/elec-2.png'
import elecpic3 from '../../../../assets/solution/elec/elec-3.png'
import elecpic4 from '../../../../assets/solution/elec/elec-4.png'
import elecpic5 from '../../../../assets/solution/elec/elec-5.png'
import elecpic6 from '../../../../assets/solution/elec/elec-6.png'
import elecpic7 from '../../../../assets/solution/elec/elec-7.png'
import elecpic8 from '../../../../assets/solution/elec/elec-8.png'

import { ListView } from 'antd-mobile';


class Water1detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='电计量方案'
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={elecpic1}/>
          <img src={elecpic2}/>
          <img src={elecpic3}/>
          <img src={elecpic4}/>
          <img src={elecpic5}/>
          <img src={elecpic6}/>
          <img src={elecpic7}/>
          <img src={elecpic8}/>
      </div>
    );
  }

}

export default Water1detail;
