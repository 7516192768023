import React, { Component } from 'react';
import './index.scss';
import {Link} from 'react-router-dom'
import banner from '../../../../assets/app/job/banner.png'
import clock from '../../../../assets/app/job/clock.png'



class JobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='招聘'
  }

  clickitem = (value) => {
    // this.props.history.push({
    //   pathname:'../../jobdetail',
    //   state:{value:value}
    // })
    <Link
            to = {{
              pathname:'/recruit/details',
              state:{value:value}
            }} 
            >
     </Link>         
  }

  render() {
    
    const Listitem = [
      {
        title:'开发工程师（嵌入式）',
        salary:'薪资面议',
        age:'25-40岁',
        education:'本科',
        experence:'3年以上',
        time:'2020/6/16',
        address:'嘉兴',
        jobdetail:[
          '1. 计量仪表及通信相关产品的开发与维护;',
          '2. 参与项目前期需求分析，技术方案设计;',
          '3. 嵌入式软件概要与详细设计，代码编写和调试，并解决测试发现的问题;',
          '4. 项目相关技术文档编写;',
          '5. 产品应用现场的联调、测试与问题分析;',
          '6. 上级领导安排的其他工作。'
        ],
        jobrequire:[
          '1. 25-40岁，本科（含）以上学历;',
          '2. 3年以上相关研发经验;',
          '3. 熟悉单片机原理及开发环境，精通C语言并能够熟练运用;',
          '4. 熟悉C++等面向对象语言优先;',
          '5. 有国网电表研发经验优先。'
        ]
      },
      {
        title:'开发工程师（通信）',
        salary:'薪资面议',
        age:'25-40岁',
        education:'本科',
        experence:'3年以上',
        time:'2020/6/16',
        address:'杭州',
        jobdetail:[
          '1. 负责通信产品及系统的开发与维护;',
          '2. 相关通信系统技术方案设计;',
          '3. 项目技术文档编写;',
          '4. 上级领导安排的其他工作。'
        ],
        jobrequire:[
          '1. 25-40岁，本科（含）以上学历;',
          '2. 3年以上通信相关产品或系统开发经验;',
          '3. 掌握通信、数字信号处理基础理论知识；了解2G/3G/4G、WIFI、蓝牙、ZigBee等常见通信系统协议;',
          '4. 熟练掌握C/C++或Java语言，具备嵌入式和桌面软件开发能力;',
          '5. 具备良好的代码编写规范、文档写作及文档管理能力;',
          '6. 较强的沟通、学习能力，良好的团队合作精神和质量意识，能承受一定的工作压力。'
        ]
      },
      {
        title:'高级JAVA开发工程师',
        salary:'薪资面议',
        age:'30-40岁',
        education:'本科',
        experence:'5年以上',
        time:'2020/6/15',
        address:'杭州',
        jobdetail:[
          '1. 负责服务端的设计、开发，大数据处理;',
          '2. 指导软件工程师的日常开发，解决开发中的技术问题;',
          '3. 协助系统交付，对项目实施提供支持;',
          '4. 负责跟进服务器安全、文档、维护和性能优化等工作;',
          '5. 上级领导安排的其他工作。',
        ],
        jobrequire:[
          '1. 30-40岁，本科（含）以上学历，5年以上Java开发经验，2年以上大型项目架构设计经验;',
          '2. 精通tcp/ip/http等通信协议，熟悉Java NIO，Netty，mysql等主流数据库开发，有较强的sql编写能力；熟悉数据库设计理论，具备一定的结合应用的数据 库调优能力，熟悉mongodb，redis等NoSQL技术，熟悉zookeeper服务等缓存技术;',
          '3. 熟练linux下服务器环境开发部署和Java性能调优，熟练SpringMVC、Spring、MyBatis等框架（框架提供的特性及其实现原理），精通大规模系统的JavaEE架构技术;',
          '4. 具备海量数据、大批量、高并发、高性能分析及处理者优先，在大型互联网公司有服务端设计和架构者优先。'
        ]
      },
      {
        title:'运维工程师',
        salary:'薪资面议',
        age:'25-35岁',
        education:'本科',
        experence:'2年以上',
        time:'2020/6/15',
        address:'杭州',
        jobdetail:[
          '1. 负责监测平台的搭建和监控;',
          '2. Windows、Linux服务器系统的日常运维工作;',
          '3. 负责服务器以及应用的日常监控，完善监控指标;',
          '4. 及时处理系统出现的各类故障，并提出分析和总结报告;',
          '5. 监控服务器的运行状态、系统性能，保障服务器的稳定性、可用性以及安全性;',
          '6. 上级领导安排的其他工作。'
        ],
        jobrequire:[
          '1. 25-35岁，本科（含）以上学历;',
          '2. 2年以上相关工作经验;',
          '3. 精通Linux及Windows系统管理和应用;',
          '4. 精通Jenkins,Docker等运维工具;',
          '5. 能承受故障突发、抗压能力强，具备较强的表达和协调能力。',
        ]
      },
      {
        title:'UED/前端工程师',
        salary:'薪资面议',
        age:'25-35岁',
        education:'本科',
        experence:'2年以上',
        time:'2020/6/14',
        address:'杭州',
        jobdetail:[
          '1. 负责产品的视觉/交互设计;',
          '2. 负责web前端系统和功能的开发、调试及维护;',
          '3. 能与产品经理、开发人员沟通，能进行页面原型转换;',
          '4. 为日常运营活动、功能改进及维护提供美术支持;',
          '5. 上级领导安排的其他工作。'
        ],
        jobrequire:[
          '1. 25-35岁，本科（含）以上学历;',
          '2. 2年以上相关工作经验;',
          '3. 精通web前段技术(javascript、css、html以及xml),熟悉iquery、ajax优先;',
          '4. 熟悉互联网和移动互联网用户体验设计的主要发展方向;',
          '5. 优秀的设计软件使用能力，对用户体验和美观有很好的把握。',
        ]
      },
      {
        title:'高级软件测试工程师',
        salary:'薪资面议',
        age:'30-40岁',
        education:'本科',
        experence:'5年以上',
        time:'2020/6/14',
        address:'杭州',
        jobdetail:[
          '1. 主导测试组的各项工作;',
          '2. 负责重要项目的具体测试;',
          '3. 负责测试方案的拟定和测试环境的搭建;',
          '4. 组织软件产品的内部技术支持;',
          '5. 组织配合其他部门的工作;',
          '6. 上级领导安排的其他工作;'
        ],
        jobrequire:[
          '1. 30-40岁，本科（含）以上学历;',
          '2. 5年以上相关工作经验，有2年以上的管理经验;',
          '3. 善于拟定测试方案，有测试环境搭建经验;',
          '4. 有较强的逻辑分析能力、总结能力、沟通协调能力。',
        ]
      },
      {
        title:'产品经理',
        salary:'薪资面议',
        age:'25-40岁',
        education:'本科',
        experence:'2年以上',
        time:'2020/6/13',
        address:'杭州',
        jobdetail:[
          '1. 软件产品策划，需求分析及产品原型设计;',
          '2. 开发管理，负责产品研发各流程把控;',
          '3. 产品上线部署及内外培训工作;',
          '4. 上线跟踪及产品优化;',
          '5. 协助销售和技术支持的工作;',
          '6. 上级领导安排的其他工作;'
        ],
        jobrequire:[
          '1. 25-40岁，本科（含）以上学历，2年以上相关工作经验，能熟练使用1-2种产品原型设计工具;',
          '2. 出色的表达能力、文档写作能力和产品原型表现能力;',
          '3. 了解Javascript,html等技术，很好的逻辑思维与分析，很好的用户思维，能耗很好地规划和设计产品功能、使用流程等;',
          '4. 熟悉产品生命周期管理，有创意、有激情、有团队合作精神，能承受较大的工作压力。',
        ]
      },
      {
        title:'质量工程师',
        salary:'薪资面议',
        age:'25-45岁',
        education:'大专',
        experence:'3年以上',
        time:'2020/6/13',
        address:'嘉兴',
        jobdetail:[
          '1. 各产品制程检验标准、文件的制定、优化;',
          '2. 产品相关的可靠性试验及验证;',
          '3. 产品实验标准及验证总结报告;',
          '4. 参与制程工艺指导文件及工装设备的评审;',
          '5. 主导制程异常的分析与处理；协调相关部门对质量问题进行分析，并监督改善措施的执行情况和效果;',
          '6. 联合工艺部门不断改进和提高制程良率;',
          '7. 上级领导安排的其他工作。',
        ],
        jobrequire:[
          '1. 25-45岁，大专（含）以上学历，有电子、计量、通信、自动化、机械、计算机、环境等专业知识背景，有3年以上相关行业同岗工作经验;',
          '2. 熟练使用office软件，ERP软件;',
          '3. 了解本行业品质标准，能够撰写品质标准;',
          '4. 熟练运用PPAP,SPC,MSA,AQL,FMEA,control plan等相关品质手法优先。'
        ]
      },
      {
        title:'国际销售经理',
        salary:'薪资面议',
        age:'20-40岁',
        education:'大专',
        experence:'2年以上',
        time:'2020/6/12',
        address:'嘉兴',
        jobdetail:[
          '1. 开拓、维护海外市场，寻求跟踪国外客户；参加对外贸易合作谈判，合同签订;',
          '2. 负责市场销售方案的策划、管理和执行;',
          '3. 及时了解市场信息，保持与客户的联系。协调、组织排产、跟踪出口交易全过程;',
          '4. 整理客户关系管理文档，按照公司要求跟进各类客户;',
          '5. 上级领导安排的其他工作。'
        ],
        jobrequire:[
          '1. 20-40岁，大专（含）以上学历，二年以上 海外相关行业同岗工作经验;',
          '2. 熟练使用office软件;',
          '3. 大学英语四级（含）以上，具有优秀的英语沟通、写作、及表达能力;',
          '4. 有一定市场营销策划经验、熟悉行业市场发展现状，具有优秀的营销技巧和市场运作能力;',
          '5. 有电子、通信、机械专业知识背景者优先。'
        ]
      },
      {
        title:'国内销售经理 ',
        salary:'薪资面议',
        age:'20-45岁',
        education:'大专',
        experence:'1年以上',
        time:'2020/6/16',
        address:'北京、天津、重庆、成都',
        jobdetail:[
          '1. 开拓、维护国内市场，寻求跟踪国内客户;',
          '2. 参与区域市场的销售管理、销售方案的策划、管理和执行;',
          '3. 及时了解市场信息，保持与客户的联系，协调并跟踪排产、销售及售后流程;',
          '4. 整理客户关系管理文档，按照公司要求跟进各类客户;',
          '5. 参加合作谈判，合同的签订;',
          '6. 上级领导安排的其他工作。'
        ],
        jobrequire:[
          '1. 20-45岁，大专（含）以上学历，一年以上相关行业同岗工作经验;',
          '2. 有燃气表、水表、热量表销售经历，且有优秀的销售业绩，熟悉行业市场发展现状，具有优秀的营销技巧和市场运作能力;',
          '3. 有很好的亲和力、语言交流能力强、能吃苦耐劳并有强烈的营销意识者;',
          '4. 有电子、机械、自动化、计量、仪器仪表、水利、环境、营销相关专业教育背景者优先。'
        ]
      }
    ]
    return (
      <div className="recruit">
        <img src={banner}/>
        {
          Listitem.map((value,index) => (
            
          <div className='recruitcontent' onClick={() => this.clickitem(value)}>
            <Link
             to = {{
              pathname:'/recruit/details',
              state:{value:value}
             }} 
            >
              <div className='firstline'>
                 <span style={{fontSize:18,color:'#2E2E2E',fontWeight:'bold'}}>{value.title}</span>
                 <span style={{float:'right',fontSize:14,color:'#224273',fontWeight:'bold'}}>{value.salary}</span>
              </div>
              <div className='secondline'>
                 <span>{value.age}</span>
                 <span style={{marginLeft:6}}>{value.education}</span>
                 <span style={{marginLeft:6}}>{value.experence}</span>
              </div>
              <div className="thirdline">
                 <img src={clock}/>
                 <span style={{marginLeft:5}}>{value.time}</span>
                 <span className="address">{value.address}</span>
              </div>
              </Link>
          </div>
          ))
        }
      </div>
    );
  }

}

export default JobList;
