import React, { Component } from 'react';
import './index.scss';

class AmsPrivacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){
  }

  render() {
    return (
      <div className="imageBlock">
          <div className='pracycontent'>
              <div className="paacytitletotal">用户服务协议和隐私政策</div>
              <div className="paravylisttitle">
               本协议为您与「AMS管理系统APP」(下称本APP)版权所有者之间所订立的契约，具有合同的法律效力，请您仔细阅读。
              </div>
              <div className="paravylisttitle">
              一、本协议内容、生效、变更。本协议内容包括协议正文及所有本APP已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。如您对协议有任何疑问，应向本APP咨询。只要您使用本APP平台服务，则本协议即对您产生约束，届时您不应以未阅读本协议的内容或者未获得本APP对您问询的解答等理由，主张本协议无效，或要求撤销本协议。您确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用本APP平台服务。本APP有权根据需要不定期地制订、修改本协议及/或各类规则，并在本APP平台公示，不再另行单独通知用户。变更后的协议和规则一经在APP公布，立即生效。如您不同意相关变更，应当立即停止使用本APP平台服务。您继续使用本APP平台服务的，即表明您接受修订后的协议和规则。
              </div>
              <div className="paravylisttitle">
              二、本APP尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本APP会按照本隐私权政策的规定使用和披露您的个人信息。但本APP将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本APP不会将这些信息对外披露或向第三方提供。本APP会不时更新本隐私权政策。您在同意本APP服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本APP服务使用协议不可分割的一部分。
              </div>

              <div className="paravylisttitle">
               1.适用范围
              </div>
              <div style={{marginTop:5,lineHeight:1.5}}>
              在您使用本APP网络服务时，本APP自动接收并记录您的电话号码、账号信息等数据。
              </div>
              <div style={{marginTop:5,lineHeight:1.5}}>
              在您使用本APP网络服务，本APP自动接收并记录的您的手机上的信息，包括但不限于使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据。
              </div>

              <div className="paravylisttitle">
              2.信息的使用
              </div>
              <div style={{marginTop:5,lineHeight:1.5}} >
              在获得您的数据之后，本APP会将其上传至服务器，以生成您的历史记录数据，以便您能够更好地使用服务。
              </div>

              <div className="paravylisttitle">
              3.信息披露
              </div>
                  <div style={{marginTop:5,paddingLeft:5,lineHeight:1.5}}>
                  a)本APP不会将您的信息披露给不受信任的第三方；
                  </div>
                  <div style={{marginTop:5,paddingLeft:5,lineHeight:1.5}}>
                  b)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
                  </div>
                  <div style={{marginTop:5,paddingLeft:5,lineHeight:1.5}}>
                  c)如您出现违反中国有关法律、法规或者相关规则的情况，需要向第三方披露；
                  </div>

              <div className="paravylisttitle">
               4.信息存储和交换
              </div>
              <div style={{marginTop:5,lineHeight:1.5}}>
              本APP收集的有关您的信息和资料将保存在本APP及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本APP收集信息和资料所在地的境外并在境外被访问、存储和展示。
              </div>

              <div className="paravylisttitle">
              5.信息安全
              </div>
              <div style={{marginTop:5,lineHeight:1.5}}>
              请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，请您立即联络本APP客服，以便本APP采取相应措施。
              </div>
              
              {/* <div className="paravylist">
              5.信息安全
              </div> */}
              <div style={{marginTop:5,lineHeight:1.5}}>
              6.任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营的不可抗力而造成的个人资料泄露、丢失、被盗用或被窜改等，本APP平台均得免责。
              </div>

              <div style={{marginTop:5,lineHeight:1.5}}>
              7.由于与本APP平台链接的其它网站所造成之个人资料泄露及由此而导致的任何法律争议和后果，本APP平台均得免责。
              </div>

              <div className="paravylisttitle">
              三、本APP平台如因系统维护或升级而需暂停服务时，将事先公告。若因线路及非本网站控制范围外的硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，本APP平台不负任何责任。
              </div>

              <div className="paravylisttitle">
              四、本APP平台使用者因为违反本声明的规定而触犯中华人民共和国法律的，一切后果自己负责，本APP平台不承担任何责任。
              </div>

              <div className="paravylisttitle">
              五、本声明未涉及的问题参见国家有关法律法规，当本声明与国家法律法规冲突时，以国家法律法规为准。
              </div>

              <div className="paravylisttitle">
              六、本APP平台不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。
              </div>

              <div className="paravylisttitle">
              七、对于因不可抗力或本APP平台不能控制的原因造成的网络服务中断或其它缺陷，本APP平台不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
              </div>

              <div className="paravylisttitle">
              八、本APP平台所有页面的版式、图片版权均为本APP平台所有，未经授权，不得用于除本APP平台之外的任何站点。
              </div>

              <div className="paravylisttitle">
              九、本APP平台之声明以及其修改权、更新权及最终解释权均属本APP平台所有。
              </div>

          </div>
      </div>
    );
  }
}

export default AmsPrivacy;
