import './App.css';
import React, { Component } from 'react';
import ProductGuide from './components/product-guide/index';
import ProductDetail from './components/product-guide/product_detail';
import { BrowserRouter as Router, Route } from "react-router-dom"


//首页
import IndexPage from './components/wx/home'

//产品-首页
import Product from './components/wx/product/home'
//产品-水表
import WaterIndex from './components/wx/product/watermeter/index'
import WaterS663 from './components/wx/product/watermeter/s663'
import WaterMec from './components/wx/product/watermeter/mec'
import WaterVoha from './components/wx/product/watermeter/voha'
import WaterPara from './components/wx/product/watermeter/para'

//产品-电表
import ElecIndex from './components/wx/product/electricmeter/index'
import ElecDds5168b from './components/wx/product/electricmeter/dds5168b'
import ElecDdsu5168 from './components/wx/product/electricmeter/ddsu5168'
import ElecDds5168_4p from './components/wx/product/electricmeter/dds5168_4p'
import ElecDtsd5168 from './components/wx/product/electricmeter/dtsd5168'
import ElecPara from './components/wx/product/electricmeter/para'

//产品-热表
import HeatIndex from './components/wx/product/heatmeter/index'
import HeatR200 from './components/wx/product/heatmeter/r200'
import HeatH100 from './components/wx/product/heatmeter/h100'

//产品-网关
import GatewayIndex from './components/wx/product/gateway/index'
import GatewayGw200 from './components/wx/product/gateway/gw200'
import Gateway2detail from './components/wx/product/gateway/joytcp'
import GatewayCV151 from './components/wx/product/gateway/cv151'
import GatewayCT213 from './components/wx/product/gateway/ct213'


//产品-软件
import Software from './components/wx/product/software'

//方案
import Solution from './components/wx/solution/home'
import SolutionWater from './components/wx/solution/water'
import SolutionHeat from './components/wx/solution/heat'
import SolutionElec from './components/wx/solution/elec'

//隐私-App使用
import PrivacyMendao from './components/app/privacy/mendao'
import PrivacyAms from './components/app/privacy/ams'
import PrivacyAmr from './components/app/privacy/amr'

//招聘-小程序使用
import JobList from './components/app/job/list'
import JobDetail from './components/app/job/detail'

//关于-App使用
import AboutAmr from './components/app/about/amr'
import AboutMeterReading from './components/app/about/meterreading'
import AboutSettle from './components/app/about/settle'
import AboutDin2p from './components/app/about/din2p'

// 下一代网关
import CT213 from './components/wx/product/gateway-next/ct213'
import CV151 from './components/wx/product/gateway-next/cv151'
import GW220 from './components/wx/product/gateway-next/gw220'

import Details from './components/manual/details'
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: '',
      query: {
        a: 1,
        b: 2
      }
    };
  }
  componentDidMount() {
    //  let pathname = window.location.pathname;
    this.setState({
      pathname: '/readmeter'
    })
  }
  render() {
    const { pathname } = this.state;
    let routerlist;
    if (pathname == '/') {
      routerlist = <div>
        <Router >
          <div>
            {/* 首页 */}
            <Route exact path="/" component={IndexPage} />
            {/* 产品中心 */}
            <Route path="/product" component={Product} exact />
            <Route path="/watermeter" component={WaterIndex} exact />
            <Route path="/watermeter/s663" component={WaterS663} />
            <Route path="/watermeter/mec" component={WaterMec} />
            <Route path="/watermeter/voha" component={WaterVoha} />
            <Route path="/watermeter/para" component={WaterPara} />


            <Route path="/elecmeter" component={ElecIndex} exact />
            <Route path="/elecmeter/dds5168b" component={ElecDds5168b} />
            <Route path="/elecmeter/ddsu5168" component={ElecDdsu5168} />
            <Route path="/elecmeter/dds5168_4p" component={ElecDds5168_4p} />
            <Route path="/elecmeter/dtsd5168" component={ElecDtsd5168} />
            <Route path="/elecmeter/para" component={ElecPara} />


            <Route path="/heatmeter" component={HeatIndex} exact />
            <Route path="/heatmeter/r200" component={HeatR200} />
            <Route path="/heatmeter/h100" component={HeatH100} />

            <Route path="/gateway" component={GatewayIndex} exact />
            <Route path="/gateway/gw200" component={GatewayGw200} />
            <Route path="/gateway/joytcp" component={Gateway2detail} />
            <Route path="/gateway/cv151" component={GatewayCV151} />
            <Route path="/gateway/ct213" component={GatewayCT213} />


            <Route path="/solution/software" component={Software} />
            {/* 解决方案 */}
            <Route path="/solution" component={Solution} exact />
            <Route path="/solution/water" component={SolutionWater} />
            <Route path="/solution/heat" component={SolutionHeat} />
            <Route path="/solution/elec" component={SolutionElec} />
          </div>
        </Router>
      </div>
    } else if (pathname == '/product') {
      routerlist = <div>
        <Router>
          <div>
            <Route path="/product" component={Product} exact />
            <Route path="/watermeter" component={WaterIndex} exact />
            <Route path="/watermeter/s663" component={WaterS663} />
            <Route path="/watermeter/mec" component={WaterMec} />
            <Route path="/watermeter/voha" component={WaterVoha} />
            <Route path="/watermeter/para" component={WaterPara} />

            <Route path="/elecmeter" component={ElecIndex} exact />
            <Route path="/elecmeter/dds5168b" component={ElecDds5168b} />
            <Route path="/elecmeter/ddsu5168" component={ElecDdsu5168} />
            <Route path="/elecmeter/dds5168_4p" component={ElecDds5168_4p} />
            <Route path="/elecmeter/dtsd5168" component={ElecDtsd5168} />
            <Route path="/elecmeter/para" component={ElecPara} />

            <Route path="/heatmeter" component={HeatIndex} exact />
            <Route path="/heatmeter/r200" component={HeatR200} />
            <Route path="/heatmeter/h100" component={HeatH100} />

            <Route path="/gateway" component={GatewayIndex} exact />
            <Route path="/gateway/gw200" component={GatewayGw200} />
            <Route path="/gateway/joytcp" component={Gateway2detail} />
            <Route path="/gateway/cv151" component={GatewayCV151} />
            <Route path="/gateway/ct213" component={GatewayCT213} />

            <Route path="/solution/software" component={Software} />
          </div>
        </Router>
      </div>
    } else if (pathname == '/solution') {
      routerlist = <div>
        <Router>
          <div>
            <Route path="/solution" component={Solution} exact />
            <Route path="/solution/water" component={SolutionWater} />
            <Route path="/solution/heat" component={SolutionHeat} />
            <Route path="/solution/elec" component={SolutionElec} />
          </div>
        </Router>
      </div>
    } else if (pathname == '/recruit') {
      routerlist = <div>
        <Router>
          <div>
            <Route path="/recruit" component={JobList} exact />
            <Route path="/recruit/details" component={JobDetail} />
          </div>
        </Router>
      </div>
    } else {
      routerlist = <div>
        <Router>
          <div>
            {/* 首页 */}
            <Route exact path="/" component={IndexPage} />
            {/* 产品中心 */}
            <Route path="/product" component={Product} exact />
            <Route path="/watermeter" component={WaterIndex} exact />
            <Route path="/watermeter/s663" component={WaterS663} />
            <Route path="/watermeter/mec" component={WaterMec} />
            <Route path="/watermeter/voha" component={WaterVoha} />
            <Route path="/watermeter/para" component={WaterPara} />

            <Route path="/elecmeter" component={ElecIndex} exact />
            <Route path="/elecmeter/dds5168b" component={ElecDds5168b} />
            <Route path="/elecmeter/ddsu5168" component={ElecDdsu5168} />
            <Route path="/elecmeter/dds5168_4p" component={ElecDds5168_4p} />
            <Route path="/elecmeter/dtsd5168" component={ElecDtsd5168} />
            <Route path="/elecmeter/para" component={ElecPara} />

            <Route path="/heatmeter" component={HeatIndex} exact />
            <Route path="/heatmeter/r200" component={HeatR200} />
            <Route path="/heatmeter/h100" component={HeatH100} />

            <Route path="/gateway" component={GatewayIndex} exact />
            <Route path="/gateway/gw200" component={GatewayGw200} />
            <Route path="/gateway/joytcp" component={Gateway2detail} />
            <Route path="/gateway/cv151" component={GatewayCV151} />
            <Route path="/gateway/ct213" component={GatewayCT213} />

            <Route path="/solution/software" component={Software} />
            {/* 解决方案 */}
            <Route path="/solution" component={Solution} exact />
            <Route path="/solution/water" component={SolutionWater} />
            <Route path="/solution/heat" component={SolutionHeat} />
            <Route path="/solution/elec" component={SolutionElec} />
            {/* 招聘 */}
            <Route path="/recruit" component={JobList} exact />
            <Route path="/recruit/details" component={JobDetail} />
            {/* 关于介绍页 */}
            <Route path="/amrintroduce" component={AboutAmr} />
            <Route path="/about/amr" component={AboutMeterReading} />
            <Route path="/about/pay" component={AboutSettle} />
            <Route path="/about/elec2p" component={AboutDin2p} />
            {/* App隐私 */}
            <Route path="/privacy" component={PrivacyMendao} />
            <Route path="/amsprivacy" component={PrivacyAms} />
            <Route path="/amrprivacy" component={PrivacyAmr} />

            {/* 产品选型手册 */}
            <Route path="/product/guide" component={ProductGuide} />
            <Route path="/product/detail" component={ProductDetail} />

            {/* CT213展示页 */}
            <Route path="/product/ct213" component={CT213} />
            {/* CV151展示页 */}
            <Route path="/product/cv151" component={CV151} />
            {/* GW220展示页 */}
            <Route path="/product/gw220" component={GW220} />
            {/* 产品说明书 */}
            <Route path="/manual" component={Details} />
          </div>
        </Router>
      </div>
    }
    return (
      <div>
        {routerlist}
      </div>
    );
  }

}

export default App;
