import React, { Component } from 'react';
import { Tag, Space } from 'antd-mobile-v5'; // V5版本的Toast会清除整个显示
import { Toast} from 'antd-mobile';
import './index.scss';
import ct213 from '../../../../../assets/product/gateway/ct213.png'


class GatewayCT213 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchparams:{},
      comm: []
    };
  }
  
  
  componentDidMount(){
    document.title='智能网关'
    let searchs = this.props.location.search;
    var key, value, params={}, comm;
    searchs.substr(1).split('&').forEach(pair=> {
      [key, value] = pair.split('=');
      params[key] = value;
    });
    comm = this.getComType(params["commod"]);
    this.setState({
        searchparams: params,
        comm: comm
    });
  }

  copyDevEui = () => {
      const copyEle = document.querySelector('#deveui') // 获取要复制的节点
      const range = document.createRange(); // 创造range
      window.getSelection().removeAllRanges(); //清除页面中已有的selection
      range.selectNode(copyEle); // 选中需要复制的节点
      window.getSelection().addRange(range); // 执行选中元素
      const copyStatus = document.execCommand("Copy"); // 执行copy操作
      // 对成功与否定进行提示
      if (copyStatus) {
        Toast.show('复制成功');
      } else {
        Toast.show('复制失败');
      }
      window.getSelection().removeAllRanges(); //清除页面中已有的selection   
  }

  //子型号确定通信方式
  getComType = (commod) => {    
    var comm = [];    
    if(commod){
      var rst = commod.match(/W(\w+)R(\w+)/i);
      if (rst.length == 3){
        if ((rst[1].indexOf('C') > -1) || (rst[1].indexOf('c') > -1)) {
          comm.push('以太网');
        }
        if ((rst[2].indexOf('A') > -1) || (rst[2].indexOf('a') > -1) || (rst[2].indexOf('B') > -1) || (rst[2].indexOf('b') > -1) ) {
          comm.push('4G');
        }                
        if (rst[2].indexOf('1') > -1 ) {
          comm.push('蓝牙');
        }
      }              
    }
    return(comm);
  } 
  
  render() {
    const {searchparams, comm} = this.state;
    return (
      <div className='mainContent'>
        <div className='imageInfo'>
          <img src={ct213}/>

          {searchparams["commod"] &&
            <div style={{ display:'inline', position: 'absolute', left: '6.6%', top: '28%'}}>
              {//<span style={{fontFamily: "monospace", color:'#FFFFFF'}}>{searchparams["commod"]}</span>
              }
              <Space style={{marginLeft: '6px'}}>
                { comm.map(co => <Tag round color='white' fill='outline'>{co}</Tag>) }
              </Space>
            </div>
          }


          {searchparams["deveui"] &&
            <div style={{ display:'inline', position: 'absolute', left: '7.5%', top: '34%'}}>
              <span style={{fontFamily: "monospace", color:'#FFFFFF'}}>DevEUI: </span>
              <span id="deveui" style={{fontFamily: "monospace", color:'#FFFFFF'}}>{searchparams["deveui"]}</span>
              <a style={{ color: '#000', marginLeft: '6px'}} 
                onClick={this.copyDevEui}>
                <div style={{ display:'inline', borderRadius: '15px', background: 'white', padding: '4px 12px 4px 12px' }}>
                  <span style={{fontSize: '80%',color:'#96CDCD'}}>复制</span>
                </div>           
              </a>
            </div>
          }
          
          {searchparams["blemac"] &&
            <div style={{ display:'inline', position: 'absolute', left: '7.5%', top: '38%'}}>
              <span style={{fontFamily: "monospace", color:'#FFFFFF'}}>BLEMac:  {searchparams["blemac"]}</span>
            </div>
          }

            <a style={{ color: '#000', position: 'absolute', left: '5%', bottom: '10%' }} 
              href="http://m.joymeter.com/manual?code=JOYC-CT213-700-001">
              <div style={{ display:'inline', marginLeft: '4px', borderRadius: '14px', background: 'white', padding: '9px 14px 9px 14px' }}>
                <span style={{ fontSize: '100%', color:'#96CDCD'}}>使用说明</span>
              </div>           
            </a>

            {/*
            <a style={{ color: '#000', position: 'absolute', left: '33%', bottom: '10%'}} 
              onClick={ () => { Toast.info("Coming soon...",3) }}>
              <div style={{ display:'inline', background: 'white', borderRadius: '14px', padding: '9px 14px 9px 14px'}}>
                <span style={{ fontSize: '100%', color:'#96CDCD'}}>使用说明</span>
              </div>           
            </a>
            */}

        </div>    
      </div>
    );
  }

}

export default GatewayCT213;
