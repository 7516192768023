import React, { Component } from 'react';
import './index.scss';
import GatewayGw200 from '../../../../../assets/product/gateway/index/gw200.png'
import GatewayJoytcp from '../../../../../assets/product/gateway/index/joytcp.png'
import GatewayCV151 from '../../../../../assets/product/gateway/index/cv151.png'
import GatewayCT213 from '../../../../../assets/product/gateway/index/ct213.png'
import {Link} from 'react-router-dom'


class GatewayIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='网关'
  }

  render() {
    return (
      <div>
        <div className="gatewayIndex">
              <div>
              <Link to="/gateway/gw200">
              <img src={GatewayGw200}/>
              </Link>
              </div>
            <div>
             <Link to="/gateway/joytcp">
               <img src={GatewayJoytcp}/>
             </Link> 
          </div>
          <div>
             <Link to="/gateway/cv151">
               <img src={GatewayCV151}/>
             </Link> 
          </div>
          <div>
             <Link to="/gateway/ct213">
               <img src={GatewayCT213}/>
             </Link> 
          </div>
        </div>
      </div>
    );
  }

}

export default GatewayIndex;
