import React, { Component } from 'react';
import './index.scss';
import s663pic1 from '../../../../../assets/product/watermeter/s663/s663-1.png'
import s663pic2 from '../../../../../assets/product/watermeter/s663/s663-2.png'
import s663pic3 from '../../../../../assets/product/watermeter/s663/s663-3.png'
import s663pic4 from '../../../../../assets/product/watermeter/s663/s663-4.png'
import s663pic5 from '../../../../../assets/product/watermeter/s663/s663-5.png'
import s663pic6 from '../../../../../assets/product/watermeter/s663/s663-6.png'
import s663pic7 from '../../../../../assets/product/watermeter/s663/s663-7.png'

import {Link} from 'react-router-dom'


class WaterS663 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsmeter:''
    };
  }
  
  
  componentDidMount(){
    document.title='水表'
    setTimeout(()=>{
      this.setState({
        paramsmeter:'水表参数>>'
      })
    },1000)
  }

  render() {
    return (
      <div className="imageBlock">
        <div>
          <img  src={s663pic1}/>
          <img  src={s663pic2}/>
          <img  src={s663pic3}/>
          <img  src={s663pic4}/>
          <img  src={s663pic5}/>
          <img  src={s663pic6}/>
        </div>

        <div  className='bottomLink-s663'>
            <img src={s663pic7}/>
            <Link to='/watermeter/para'><div>{this.state.paramsmeter}</div></Link>
          </div>

      </div>
    );
  }

}

export default WaterS663;
