import React, { Component } from 'react';
import { NavBar, Icon, Tabs, WhiteSpace, Flex, List, Modal, Button, Toast, Result } from 'antd-mobile';
import { StickyContainer } from 'react-sticky';
import { Link } from 'react-router-dom';
import { createForm } from 'rc-form';
import { default as IndexBar } from './index-bar/index';
import { default as Selector } from './selector/index';
import { default as Tag } from './tag/index';
import { default as Empty } from './empty/index';
import filter from '../../assets/productGuide/filter.png';
import filterSelect from '../../assets/productGuide/filter-select.png';
import './index.scss';
import { Valve, ColdHot, BaseMaterial, WaterCommMode, TemperatureRange, HeatBaseMaterial, HeatCommMode,
  Specification, InstallType, RateType, EletricCommMode, CommDown, CommUp, Payload, Relay, Induction, CommAntenna} from './list';
import Axios from 'axios';

// const SPT_HOST = "http://192.168.10.142:2705";
const SPT_HOST = "http://182.92.242.121:2705";

const tabs = [
  { title: '水表', key: '10' },
  { title: '热表', key: '20' },
  { title: '电表', key: '40' },        
  { title: '网关', key: '90' },
];

// 企业微信应用ID
const AGENT_ID = "1000008";

class ProductGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {
        productList: [],
        count: 0,
        modal1: false,
        tabKey: '',
        loadComplete: false,
        showPage: false,
        viewer:''
    };
  }

  getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
    }
    return(false);
  }

  formatData = (bodyData) => {
    let formData = new FormData();
    for (const key in bodyData) {
      if (bodyData.hasOwnProperty(key)) {
        const element = bodyData[key];
        if (element == 0) formData.append(key, element);
        else if (element) formData.append(key, element);
      }
    }
    return formData;
  }

  componentDidMount = () => {

    let paraCode = this.getQueryVariable("code");
    let paraState = this.getQueryVariable("state");
    if(paraCode!=false&&paraState!=false&&paraState=='qyapi'){
      this.setState({
        showPage:true
      });
      const values = {
        code: paraCode,
        state: paraState,
        agentId: AGENT_ID
      };
      Axios({
        url:`${SPT_HOST}/teambition/getWeChatUser`,
        method:'POST',
        data:this.formatData(values)
      }).then(res => {
          if (res.data.status == 1) {
            this.setState({
              showPage:true,
              viewer: res.data.object //浏览者备用
            });
          } else {
            this.setState({
              showPage:false
            });
          }          
      }).catch(error => {
          throw new Error(error)
      })
    }else{
      this.setState({
        showPage:false
      })
    }
    
    const productType = window.sessionStorage.getItem("productType") || '10';  
    const conditions = window.sessionStorage.getItem("conditions");
    if (conditions) {
      this.filterProduct(JSON.parse(conditions), productType);
    } else {
      this.loadData(productType);  
    }    

    document.title='产品选型手册';
  }

  loadData = (productType) => {
    const productList = JSON.parse(window.sessionStorage.getItem("product_" + productType));
    if (productList) {
      this.loadDataFromCache(productType);
    } else {
      this.loadDataFromServer(productType); 
    }
  }

  loadDataFromServer = (productType) => {
    Toast.loading('加载中...', 0, null, false);
    this.setState({ loadComplete: false });
    Axios.get(`${SPT_HOST}/product/queryProductGroup?productType=${productType}`)
      .then(response => {
        const data = response.data;
        if (data && data.status == 1) {
          let result = data.object.reduce((init, current) => { return init + current.items.length }, 0);
          this.setState({
            productList: data.object,
            count: result,
          });    
          window.sessionStorage.setItem("product_" + productType, JSON.stringify(data.object)); 
        }       
        Toast.hide();
        this.setState({ 
          loadComplete: true,          
          tabKey: productType + ''
        });
    }).catch(error => {
        console.log(error);
    });
  }

  loadDataFromCache = (productType) => {    
    const productList = JSON.parse(window.sessionStorage.getItem("product_" + productType));
    let result = productList && productList.length > 0 && productList.reduce((init, current) => { return init + current.items.length }, 0);
    this.setState({
      productList: productList,
      count: result,        
      tabKey: productType + ''
    });
  }

  showModal = key => (e) => {
    e.preventDefault(); // 修复 Android 上点击穿透
    this.setState({
      [key]: true,
    });
  }

  onClose = key => (e) => {
    e.preventDefault();
    this.setState({
      [key]: false,
    });
  }

  onTabClick = (productType) => {
    this.setState({
      productList: [],
      count: 0,
      tabKey: productType
    });
    this.props.form.resetFields();
    window.sessionStorage.removeItem("conditions");
    window.sessionStorage.setItem("productType", productType);

    this.loadData(productType);
  }

  reset = () => {
    this.setState({
      productList: [],
      count: 0,
    });
    this.props.form.resetFields();
    window.sessionStorage.removeItem("conditions"); 
    this.loadData(this.state.tabKey);
 
    this.setState({
      modal1: false,
    });
  }

  onSubmit = () => {
    this.props.form.validateFields({ force: true }, (error) => {
      if (!error) {
        this.filterProduct(this.props.form.getFieldsValue(), this.state.tabKey);   
        this.onClose('modal1');     
      } else {
        alert.log('Validation failed');
      }
    });
  }

  filterProduct = (conditions, productType) => {
    Object.keys(conditions).map(key => {
      if (!conditions[key]) {
        delete  conditions[key];
      }
    });

    if (conditions['caliber']) {
      conditions.caliber = "DN" + conditions['caliber'].replace('DN', '');
    }

    Toast.loading('加载中...', 0, null, false);    
    this.setState({ loadComplete: false });
    Axios.post(`${SPT_HOST}/product/queryByCondition`,
      {
        ...conditions,
        productType: productType,
      }
    ).then(response => {
      const data = response.data;
      let result = data.object && data.object.reduce((init, current) => { return init + current.items.length }, 0);
      this.setState({
        productList: data.object,
        count: result,
      });
      // 筛选后的条件
      window.sessionStorage.setItem("conditions", JSON.stringify(conditions)); 
      Toast.hide();
      this.setState({
        modal1: false,
        loadComplete: true,       
        tabKey: productType + '' 
      });      
    }).catch(error => {
        console.log(error);
    });
  }

  render() {
    const productType = window.sessionStorage.getItem("productType");
    const { getFieldDecorator } = this.props.form;
    const conditionsStr = window.sessionStorage.getItem("conditions");
    let conditions;
    let filterFlg = false;    
    if (conditionsStr) {
      conditions = JSON.parse(conditionsStr);      
      if (conditions) {
        filterFlg = true;
      }
    }

    return (
      <div>
      { this.state.showPage && (<div>
        {/* <NavBar
            mode="light"
            icon={<Link to={{ pathname:'/product', state: {} }}>
                    <Icon type="left" style={{ color: '#1677ff'}} />
                  </Link>}
            onLeftClick={() => console.log('onLeftClick')}
            rightContent={[
                <Icon key="1" type="ellipsis" />
            ]}
        >
          产品选型手册
        </NavBar> */}
        <StickyContainer>
            <Tabs tabs={tabs}
                initialPage={ productType || this.state.tabKey }
                useOnPan={false}
                swipeable={false}  
                onTabClick={(tab, index) => this.onTabClick(tab.key) }                
            > 
              <div style={{ height: window.innerHeight, width: '100%', overflowY: 'hidden' }}>                   
                  <WhiteSpace />
                  <Flex>
                      <Flex.Item style={{ padding: '0 12px'}}>{`全部（${this.state.count}）`}</Flex.Item>
                      <Flex.Item></Flex.Item>
                      <Flex.Item>
                          <div style={{ float: 'right', padding: '0 12px', color: filterFlg ? '#1677ff' : "black"}}>
                              <a onClick={this.showModal('modal1')}>筛选
                              <img src={filterFlg ? filterSelect : filter} alt="" style={{ width: 19, height: 19, verticalAlign: 'middle' }} /></a>
                          </div>
                      </Flex.Item>
                  </Flex>
                  <WhiteSpace />
                  { this.state.loadComplete && this.state.productList.length == 0 ? <Empty style={{ padding: '164px 0' }} imageStyle={{ width: 128 }} description='暂无数据'/> : (
                    <IndexBar>
                      {this.state.productList.map(group => {
                        const { categoryId, items } = group
                        return (
                            <IndexBar.Panel 
                              index={categoryId}
                              group={group}
                              key={categoryId}
                            >
                              <List style={{ padding: '6px 0px', border: '0px' }} prefixCls="xxx1" >
                                  {items.map((item, index) => (
                                    <Link
                                      to = {{
                                        pathname:'/product/detail',
                                        state: { item: item }
                                      }} 
                                      key={item.productId}
                                    >                                          
                                      <List.Item className="list-body" prefixCls="xxx2" >                                      
                                        <Flex style={{ fontSize: 12, fontWeight: 600, padding: '2px' }}>
                                          {item.model} {item.productName}
                                        </Flex> 
                                        {this.state.tabKey == '10' && (
                                          <div>
                                            <Flex>
                                              <Flex.Item style={{ borderRight: '0.5px solid #f0f0f0' }}>  
                                                <div className="item-title">口径</div>                                 
                                                <div className="item-data">{item.caliber}</div>  
                                              </Flex.Item>
                                              <Flex.Item>  
                                                <div className="item-title">基表材质</div>                                 
                                                <div className="item-data">{item.baseMaterial}</div> 
                                              </Flex.Item>
                                            </Flex>  
                                            <Flex style={{ padding: '4px 0px', borderBottom: '0.5px solid #f0f0f0' }}>
                                              <Tag fill='outline' size="small" >{item.commMode}</Tag>
                                              { item.valve && ( <Tag fill='outline' style={{ marginLeft: 6 }}>{item.valve}</Tag>)}
                                            </Flex>         
                                          </div>
                                        )}        
                                        {this.state.tabKey == '20' && (
                                          <div>
                                            <Flex>
                                              <Flex.Item style={{ borderRight: '0.5px solid #f0f0f0' }}>  
                                                <div className="item-title">温度范围</div>                                 
                                                <div className="item-data">{item.temperatureRange}</div>  
                                              </Flex.Item>
                                              <Flex.Item>  
                                                <div className="item-title">口径</div>                                 
                                                <div className="item-data">{item.caliber}</div> 
                                              </Flex.Item>
                                            </Flex>  
                                            <Flex style={{ padding: '4px 0px', borderBottom: '0.5px solid #f0f0f0' }}>                                              
                                              <Flex.Item style={{ borderRight: '0.5px solid #f0f0f0' }}>                                                  
                                                <div className="item-title">基表材质</div>                                 
                                                <div className="item-data">{item.baseMaterial}</div> 
                                              </Flex.Item>
                                              <Flex.Item>  
                                                <Tag fill='outline' size="small" >{item.commMode}</Tag>
                                              </Flex.Item>
                                            </Flex>         
                                          </div>
                                        )}        
                                        {this.state.tabKey == '40' && (
                                          <div>
                                            <Flex>
                                              <Flex.Item style={{ borderRight: '0.5px solid #f0f0f0' }}>  
                                                <div className="item-title">规格</div>                                 
                                                <div className="item-data">{item.specification}</div>  
                                              </Flex.Item>
                                              <Flex.Item>  
                                                <div className="item-title">是否带继电器</div>                                 
                                                <div className="item-data">{item.relay == 'Y' ? '是' : '否'}</div> 
                                              </Flex.Item>
                                            </Flex>  
                                            <Flex>
                                              <Flex.Item style={{ borderRight: '0.5px solid #f0f0f0' }}>  
                                                <div className="item-title">直通/互感</div>                                 
                                                <div className="item-data">{item.induction}</div>  
                                              </Flex.Item>
                                              {item.commMode == 'NB-IoT' ? (
                                                <Flex.Item>  
                                                  <div className="item-title">通讯天线</div>                                 
                                                  <div className="item-data">{item.commAntenna || '-'}</div> 
                                                </Flex.Item>
                                              ) : (
                                                <Flex.Item>  
                                                  <div className="item-title">费率</div>                                 
                                                  <div className="item-data">{item.rateType}</div> 
                                                </Flex.Item>
                                              )}                                              
                                            </Flex>  
                                            <Flex style={{ padding: '4px 0px', borderBottom: '0.5px solid #f0f0f0' }}>                                              
                                              <Flex.Item style={{ borderRight: '0.5px solid #f0f0f0' }}>  
                                                <div className="item-title">安装方式</div>                                 
                                                <div className="item-data">{item.installType}</div> 
                                              </Flex.Item>
                                              <Flex.Item>  
                                                <Tag fill='outline' size="small" >{item.commMode}</Tag>                                                
                                              </Flex.Item>
                                            </Flex>         
                                          </div>
                                        )}        
                                        {this.state.tabKey == '90' && (
                                          <div>
                                            {item.payload && (
                                              <Flex>
                                                <Flex.Item style={{ borderRight: '0.5px solid #f0f0f0' }}>  
                                                  <div className="item-title">载荷</div>                                 
                                                  <div className="item-data">{item.payload}</div> 
                                                </Flex.Item>
                                                <Flex.Item></Flex.Item>
                                              </Flex>  
                                            )}
                                            <Flex style={{ padding: '4px 0px', borderBottom: '0.5px solid #f0f0f0' }}>             
                                              <Flex.Item style={{ borderRight: '0.5px solid #f0f0f0' }}>  
                                                <div className="item-title">下行通信</div>                                 
                                                <div className="item-data"><Tag fill='outline' size="small">{item.commDown}</Tag> </div>  
                                              </Flex.Item>
                                              <Flex.Item>  
                                                <div className="item-title">上行通信</div>                                 
                                                <div className="item-data"><Tag fill='outline' size="small" >{item.commUp}</Tag> </div> 
                                              </Flex.Item> 
                                            </Flex>         
                                          </div>
                                        )}                                       
                                      </List.Item>
                                    </Link>      
                                  ))}
                              </List>
                            </IndexBar.Panel>
                          )
                      })}                                                    
                      <WhiteSpace size="lg"/>
                      <WhiteSpace size="lg"/>
                      <WhiteSpace size="lg"/>
                    </IndexBar>
                  )}
              </div>  
            </Tabs>
        </StickyContainer>
        <WhiteSpace />  
        <Modal
          popup
          visible={this.state.modal1}
          onClose={this.onClose('modal1')}
          animationType="slide-up"          
        >
          <List renderHeader={() => <div style={{ fontSize: 14, fontWeight: 600 }}>条件筛选</div>} className="popup-list">
            { this.state.tabKey == '10' && (
              <div>               
                <List.Item multipleLine>
                  <Flex>
                    <Flex.Item>
                      <span className="selector-item-title">冷热</span> 
                      <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                        {getFieldDecorator('coldHot', {
                          initialValue: conditions && conditions.coldHot
                        })
                          (<Selector           
                              options={ColdHot}
                              multiple={true}
                              columns={4}
                            />
                        )}  
                      </List.Item.Brief>
                    </Flex.Item>
                    <Flex.Item>
                      <span className="selector-item-title">阀控</span>
                      <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                        {getFieldDecorator('valve', {
                          initialValue: conditions && conditions.valve
                        })
                          (<Selector           
                              options={Valve}
                              multiple={true}
                              columns={4}
                            />
                        )}  
                      </List.Item.Brief>
                    </Flex.Item>
                  </Flex>                  
                </List.Item>
                <List.Item multipleLine>
                  <span className="selector-item-title">口径</span> 
                  <List.Item.Brief style={{ fontSize: 10, whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('caliber', {
                      initialValue: conditions && conditions.caliber && conditions.caliber.replace('DN', ''),
                    })
                      (<div>
                          <span className="button-prefix">DN</span>
                          <input className="input" name="caliber" type="number" placeholder="请输入"/>
                      </div>
                    )}  
                  </List.Item.Brief>
                </List.Item>
                <List.Item multipleLine>
                  <span className="selector-item-title">基表材质</span> 
                  <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('baseMaterial', {
                      initialValue: conditions && conditions.baseMaterial
                    })
                      (<Selector           
                          options={BaseMaterial}
                          multiple={true}
                          columns={4}
                        />
                    )}  
                  </List.Item.Brief>
                </List.Item>
                <List.Item multipleLine>
                  <span className="selector-item-title">通信方式</span>  
                  <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('commMode', {
                      initialValue: conditions && conditions.commMode
                    })
                      (<Selector           
                          options={WaterCommMode}
                          multiple={true}
                          columns={4}
                        />
                    )}    
                  </List.Item.Brief>
                </List.Item>
                </div>
              )}
              { this.state.tabKey == '20' && (
              <div>               
                <List.Item multipleLine>
                  <span className="selector-item-title">温度范围</span> 
                  <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('temperatureRange', {
                      initialValue: conditions && conditions.temperatureRange
                    })
                      (<Selector           
                          options={TemperatureRange}
                          multiple={true}
                          columns={4}
                        />
                    )}  
                  </List.Item.Brief>
                </List.Item>
                <List.Item multipleLine>
                  <span className="selector-item-title">口径</span> 
                  <List.Item.Brief style={{ fontSize: 10, whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('caliber', {
                      initialValue: conditions && conditions.caliber
                    })
                      (<div>
                          <span className="button-prefix">DN</span>
                          <input className="input" type="number" placeholder="请输入"/>
                        </div>
                    )}  
                  </List.Item.Brief>
                </List.Item>
                <List.Item multipleLine>
                  <span className="selector-item-title">基表材质</span> 
                  <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('baseMaterial', {
                      initialValue: conditions && conditions.baseMaterial
                    })
                      (<Selector           
                          options={HeatBaseMaterial}
                          multiple={true}
                          columns={4}
                        />
                    )}  
                  </List.Item.Brief>
                </List.Item>
                <List.Item multipleLine>
                  <span className="selector-item-title">通信方式</span>  
                  <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('commMode', {
                      initialValue: conditions && conditions.commMode
                    })
                      (<Selector           
                          options={HeatCommMode}
                          multiple={true}
                          columns={4}
                        />
                    )}    
                  </List.Item.Brief>
                </List.Item>                
                </div>
              )}
              { this.state.tabKey == '40' && (
              <div>               
                <List.Item>
                  <span className="selector-item-title">规格</span> 
                  <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('specification', {
                      initialValue: conditions && conditions.specification
                    })
                      (<Selector           
                          options={Specification}
                          multiple={true}
                          columns={4}
                        />
                    )}  
                  </List.Item.Brief>
                </List.Item>  
                <List.Item>
                  <Flex>
                    <Flex.Item>  
                      <span className="selector-item-title">是否带继电器</span> 
                      <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                        {getFieldDecorator('relay', {
                          initialValue: conditions && conditions.relay
                        })
                          (<Selector           
                              options={Relay}
                              multiple={true}
                              columns={4}
                            />
                        )}  
                      </List.Item.Brief>
                    </Flex.Item>
                    <Flex.Item>  
                      <span className="selector-item-title">费率</span>
                      <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                        {getFieldDecorator('rateType', {
                          initialValue: conditions && conditions.rateType
                        })
                          (<Selector           
                              options={RateType}
                              multiple={true}
                              columns={4}
                            />
                        )}  
                      </List.Item.Brief>
                    </Flex.Item>
                  </Flex>
                </List.Item>  
                <List.Item>                  
                  <Flex>
                    <Flex.Item>  
                      <span className="selector-item-title">直通/互感</span> 
                      <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                        {getFieldDecorator('induction', {
                          initialValue: conditions && conditions.induction
                        })
                          (<Selector           
                              options={Induction}
                              multiple={true}
                              columns={4}
                            />
                        )}  
                      </List.Item.Brief>
                    </Flex.Item>
                    <Flex.Item>  
                      <span className="selector-item-title">通讯天线</span>
                      <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                        {getFieldDecorator('commAntenna', {
                          initialValue: conditions && conditions.commAntenna
                        })
                          (<Selector           
                              options={CommAntenna}
                              multiple={true}
                              columns={4}
                            />
                        )}  
                      </List.Item.Brief>
                    </Flex.Item>
                  </Flex>  
                </List.Item>  
                
                <List.Item>
                  <span className="selector-item-title">通信方式</span>  
                  <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('commMode', {
                      initialValue: conditions && conditions.commMode
                    })
                      (<Selector           
                          options={EletricCommMode}
                          multiple={true}
                          columns={4}
                        />
                    )}    
                  </List.Item.Brief>
                </List.Item>                
                <List.Item>
                  <span className="selector-item-title">安装方式</span>
                  <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('installType', {
                      initialValue: conditions && conditions.installType
                    })
                      (<Selector           
                          options={InstallType}
                          multiple={true}
                          columns={4}
                        />
                    )}  
                  </List.Item.Brief>
                </List.Item>
                </div>
              )}
              { this.state.tabKey == '90' && (
              <div>               
                <List.Item multipleLine>
                  <span className="selector-item-title">通信方式-下行</span> 
                  <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('commDown', {
                      initialValue: conditions && conditions.commDown
                    })
                      (<Selector           
                          options={CommDown}
                          multiple={true}
                          columns={4}
                        />
                    )}  
                  </List.Item.Brief>
                </List.Item>  
                <List.Item multipleLine>
                  <span className="selector-item-title">通信方式-上行</span>  
                  <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('commUp', {
                      initialValue: conditions && conditions.commUp
                    })
                      (<Selector           
                          options={CommUp}
                          multiple={true}
                          columns={4}
                        />
                    )}    
                  </List.Item.Brief>
                </List.Item>
                <List.Item multipleLine>
                  <span className="selector-item-title">带载量</span>
                  <List.Item.Brief style={{ whiteSpace: 'pre-wrap' }}>
                    {getFieldDecorator('payload', {
                      initialValue: conditions && conditions.payload
                    })
                      (<Selector           
                          options={Payload}
                          multiple={true}
                          columns={4}
                        />
                    )}  
                  </List.Item.Brief>
                </List.Item>              
                </div>
              )}
          </List>
          <Flex style={{ padding: "12px 24px 48px 24px"}}>
            <Flex.Item>
              <Button size="small" type="ghost" prefixCls="custom"
                className="button-reset" 
                onClick={this.reset.bind(this)}>
                重置
              </Button>
            </Flex.Item>                    
            <Flex.Item style={{ margin: 0 }}>
              <Button size="small" type="primary" prefixCls="custom"
                className="button-ok"
                onClick={this.onSubmit}>
                完成
              </Button>
            </Flex.Item>
          </Flex>
        </Modal>      
    </div>)}
    
    {      
      this.state.showPage==false && (
      <div>
        <Result
          status='warning'
          img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
          title="出现错误!"
          message="只能通过企业微信进入浏览!"
        />
      </div>
      )      
    
    }
    </div>
    );

  }
}

export default createForm()(ProductGuide);