import React, { Component } from 'react';
import './index.scss';
import vohapic1 from '../../../../../assets/product/watermeter/voha/voha-1.png'
import vohapic2 from '../../../../../assets/product/watermeter/voha/voha-2.png'
import vohapic3 from '../../../../../assets/product/watermeter/voha/voha-3.png'
import vohapic4 from '../../../../../assets/product/watermeter/voha/voha-4.png'
import vohapic5 from '../../../../../assets/product/watermeter/voha/voha-5.png'
import vohapic6 from '../../../../../assets/product/watermeter/voha/voha-6.png'
import vohapic7 from '../../../../../assets/product/watermeter/voha/voha-7.png'

import {Link} from 'react-router-dom'


class Water2detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsmeter:''
    };
  }
  
  componentDidMount(){
    document.title='水表'
    setTimeout(()=>{
      this.setState({
        paramsmeter:'水表参数>>'
      })
    },1000)
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={vohapic1}/>
          <img src={vohapic2}/>
          <img src={vohapic3}/>
          <img src={vohapic4}/>
          <img src={vohapic5}/>
          <img src={vohapic6}/>
          <div className="bottomLink-voha">
          <img src={vohapic7}/>
          <Link to='/watermeter/para'><div>{this.state.paramsmeter}</div></Link>
          </div>

      </div>
    );
  }

}

export default Water2detail;
