import React, { useContext, useEffect } from 'react'
import { NativeProps, withNativeProps } from '../../../utils/native-props'
import { IndexBarContext } from './context'
import { WhiteSpace, Toast } from 'antd-mobile'
import caretRight  from '../../../assets/productGuide/caret-right.png'
// water
import category_1  from '../../../assets/productGuide/water/category_1.png'
import category_2  from '../../../assets/productGuide/water/category_2.png'
import category_3  from '../../../assets/productGuide/water/category_3.png'
import category_4  from '../../../assets/productGuide/water/category_4.png'
import category_5  from '../../../assets/productGuide/water/category_5.png'
import category_6  from '../../../assets/productGuide/water/category_6.png'
import category_7  from '../../../assets/productGuide/water/category_7.png'
import category_8  from '../../../assets/productGuide/water/category_8.png'
import category_9  from '../../../assets/productGuide/water/category_9.png'
import category_10  from '../../../assets/productGuide/water/category_10.png'

// heat
import category_12  from '../../../assets/productGuide/heat/category_12.png'
import category_13  from '../../../assets/productGuide/heat/category_13.png'

// eletric
import category_14  from '../../../assets/productGuide/eletric/category_14.png'
import category_15  from '../../../assets/productGuide/eletric/category_15.png'
import category_16  from '../../../assets/productGuide/eletric/category_16.png'
import category_17  from '../../../assets/productGuide/eletric/category_17.png'
import category_18  from '../../../assets/productGuide/eletric/category_18.png'
import category_19  from '../../../assets/productGuide/eletric/category_19.png'
import category_20  from '../../../assets/productGuide/eletric/category_20.png'
import category_21  from '../../../assets/productGuide/eletric/category_21.png'

// gateway
import category_24  from '../../../assets/productGuide/gateway/category_24.png'
import category_25  from '../../../assets/productGuide/gateway/category_25.png'
import category_26  from '../../../assets/productGuide/gateway/category_26.png'
import category_27  from '../../../assets/productGuide/gateway/category_27.png'
import category_28  from '../../../assets/productGuide/gateway/category_28.png'
import category_31  from '../../../assets/productGuide/gateway/category_31.png'
import category_32  from '../../../assets/productGuide/gateway/category_32.png'
import category_33  from '../../../assets/productGuide/gateway/category_33.png'
import category_34  from '../../../assets/productGuide/gateway/category_34.png'

const classPrefix = `adm-index-bar-anchor`

const imageMap: any = {
  category_1: category_1,
  category_2: category_2,
  category_3: category_3,
  category_4: category_4,
  category_5: category_5,
  category_6: category_6,
  category_7: category_7,
  category_8: category_8,
  category_9: category_9,
  category_10: category_10,
  category_12: category_12,
  category_13: category_13,
  category_14: category_14,
  category_15: category_15,
  category_16: category_16,
  category_17: category_17,
  category_18: category_18,
  category_19: category_19,
  category_20: category_20,
  category_21: category_21,
  category_24: category_24,
  category_25: category_25,
  category_26: category_26,
  category_27: category_27,
  category_28: category_28,
  category_31: category_31,
  category_32: category_32,
  category_33: category_33,
  category_34: category_34,
}

export type IndexBarAnchorProps = {
  index: string
  group: any
} & NativeProps

export const Panel: React.FC<IndexBarAnchorProps> = props => {  
  const { setIndexes, groups, setGroups } = useContext(IndexBarContext)
  useEffect(() => {
    setIndexes(val => val.concat([props.index]))
    return () => {
      setIndexes(val => val.filter(x => x != props.index))
    }
  }, [props.index])

  useEffect(() => {
    setGroups(val => val.concat([props.group]))
    return () => {
      setGroups(val => val.filter(x => x.categoryId !== props.index))
    }
  }, [props.group])

  let categoryHeight = 60;
  if (imageMap.hasOwnProperty(`category_${props.group.categoryId}`)) {
    categoryHeight = 100;
  }
  
  return withNativeProps(
    props,
    <div data-index={props.index} className={classPrefix}>
      {/* 大分类 */}
      <div style={{ height: categoryHeight, width: '98%', borderRadius: '6px', backgroundImage: 'linear-gradient(120deg, #93AEBC, #DCEEFA)', padding: '12px 6px 6px 12px' }}>  
        <div style={{ float: 'left', height: '100%', width: '60%' }}>
          <div style={{ color: 'white', fontSize: 14, fontWeight: 600 }}>{props.group.categoryName}</div>
          {props.group.description ?
              (<div style={{ color: 'white', fontSize: 10, fontWeight: 400, padding: '6px 0px' }}>{props.group.description}</div>) :
              <div><WhiteSpace size="lg" />
              <WhiteSpace/></div>
          }
          <WhiteSpace />
          <div>
            { props.group.material == 'Y' && (
              <a style={{ color: '#000' }} 
                onClick={ () => { props.group.materialLink ? window.location.href = props.group.materialLink : Toast.info('Coming soon...', 1.5);
            }}>
                <div style={{ display:'inline', borderRadius: '2px', background: 'white', padding: '2px 2px 4px 4px' }}>
                  <span style={{ fontSize: 10 }}>产品说明</span>
                  <img src={caretRight} alt="" style={{ height: 12, width: 12, verticalAlign: 'middle' }}></img>
                </div>              
              </a>
            )}            
            { props.group.instruction == 'Y' && (
              <a style={{ color: '#000' }} 
                onClick={ () => { props.group.instructionLink ? window.location.href = props.group.instructionLink : Toast.info('Coming soon...', 1.5)}}>
                <div style={{ display:'inline', marginLeft: '4px', borderRadius: '2px', background: 'white', padding: '2px 2px 4px 4px' }}>
                  <span style={{ fontSize: 10 }}>使用说明</span>
                  <img src={caretRight} alt="" style={{ height: 12, width: 12, verticalAlign: 'middle' }}></img>
                </div>           
              </a>
            )}              
          </div>             
        </div>  
        {/* <div style={{ float: 'right', verticalAlign:'middle', textAlign:'center', height: '100%', width: '40%', padding: '0px 12px 0px 0px' }}> */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '40%' }}>
          <img src={ imageMap[`category_${props.group.categoryId}`] } style={{ height: 'auto', width: 'auto', maxHeight: '115%', maxWidth: '115%' }}></img>
        </div>          
      </div>  
      {props.children}
    </div>
  )
}
