import React, { Component } from 'react';
import './index.scss';
import WaterS663 from '../../../../../assets/product/watermeter/index/s663.png'
import WaterMec  from '../../../../../assets/product/watermeter/index/mec.png'
import WaterVoha from '../../../../../assets/product/watermeter/index/voha.png'
import {Link} from 'react-router-dom'


class WaterIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){
    document.title='水表'
  }

  render() {
    return (
        <div className="meterIndex">
              <div>
              <Link to="/watermeter/s663">
              <img src={WaterS663} />
              </Link>
              </div>
            <div>
             <Link to="/watermeter/mec">
               <img src={WaterMec}/>
             </Link> 
            </div>
            <div>
             <Link to="/watermeter/voha">
               <img src={WaterVoha}/>
             </Link> 
            </div>
        </div>

    );
  }

}

export default  WaterIndex;
