import React, { Component } from 'react';
import './index.scss';
import joytcppic1 from '../../../../../assets/product/gateway/joytcp/joytcp-1.png'
import joytcppic2 from '../../../../../assets/product/gateway/joytcp/joytcp-2.png'
import joytcppic3 from '../../../../../assets/product/gateway/joytcp/joytcp-3.png'
import joytcppic4 from '../../../../../assets/product/gateway/joytcp/joytcp-4.png'
import joytcppic5 from '../../../../../assets/product/gateway/joytcp/joytcp-5.png'
import joytcppic6 from '../../../../../assets/product/gateway/joytcp/joytcp-6.png'



class GatewayJoytcp extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='网关'
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={joytcppic1}/>
          <img src={joytcppic2}/>
          <img src={joytcppic3}/>
          <img src={joytcppic4}/>
          <img src={joytcppic5}/>
          <img src={joytcppic6}/>
      </div>
    );
  }

}

export default GatewayJoytcp;
