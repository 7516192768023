import React, { Component } from 'react';
import './index.scss';
import {Link } from "react-router-dom"

import mrpic1 from '../../../../assets/app/about/meterreading/meterreading-1.png'
import mrpic2 from '../../../../assets/app/about/meterreading/meterreading-2.png'
import mrpic3 from '../../../../assets/app/about/meterreading/meterreading-3.png'
import mrpic4 from '../../../../assets/app/about/meterreading/meterreading-4.png'
import mrpic5 from '../../../../assets/app/about/meterreading/meterreading-5.png'
import mrpic6 from '../../../../assets/app/about/meterreading/meterreading-6.png'
import mrpic7 from '../../../../assets/app/about/meterreading/meterreading-7.png'

class MeterReading extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){
  }

  render() {
    return (
      <div className="imageBlock">
          {/* <p style={{paddingLeft:'5%',fontSize:24,paddingTop:10 }}>详情页</p> */}
          <img src={mrpic1} className="backgroundbg"/>
          <img src={mrpic2} className="backgroundbg"/>
          <img src={mrpic3} className="backgroundbg"/>
          <img src={mrpic4} className="backgroundbg"/>    
          <img src={mrpic5} className="backgroundbg"/>
          <img src={mrpic6} className="backgroundbg"/>
          <img src={mrpic7} className="backgroundbg"/>
      </div>
    );
  }
}

export default MeterReading;
