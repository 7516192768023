import React, { Component } from 'react';
import './index.scss'
import {Link } from "react-router-dom"

import center  from '../../../../assets/product/home/center.png';
import water from '../../../../assets/product/home/water.png'
import elec from '../../../../assets/product/home/elec.png'
import gas from '../../../../assets/product/home/gas.png'
import gateway from '../../../../assets/product/home/gateway.png'
import heat from '../../../../assets/product/home/heat.png'
import other from '../../../../assets/product/home/other.png'

import WaterS663 from '../../../../assets/product/watermeter/index/s663.png'
import WaterMec from '../../../../assets/product/watermeter/index/mec.png'
import WaterVoha from '../../../../assets/product/watermeter/index/voha.png'
import ElecDds5168b from '../../../../assets/product/electricmeter/index/dds5168b.png'
import ElecDdsu5168 from '../../../../assets/product/electricmeter/index/ddsu5168.png'
import ElecDds5168_4p from '../../../../assets/product/electricmeter/index/dds5168_4p.png'
import ElecDtsd5168 from '../../../../assets/product/electricmeter/index/dtsd5168.png'
import HeatR200 from '../../../../assets/product/heatmeter/index/r200.png'
import HeatH100 from '../../../../assets/product/heatmeter/index/h100.png'
import GatewayGw200 from '../../../../assets/product/gateway/index/gw200.png'
import GatewayJoytcp from '../../../../assets/product/gateway/index/joytcp.png'



class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }

  componentDidMount(){
    document.title='产品中心'
  }

  render() {
    return (
      <div className="contentbg">
        
        <div className="contentproductbg">
           <img src={center}/>
        </div>

        <div className="productlist">
           <div className="listitem">
             <Link to='/watermeter'>
              <div><img src={water}/></div>
              <p>水表</p>
              </Link>
           </div>

           <div className="listitem">
               <Link to="/elecmeter">
                <div><img src={elec}/></div>
                <p>电表</p>
               </Link>
           </div>
           <div className="listitem">
               <div><img src={gas}/></div>
               <p style={{color:'#999'}}>气表</p>
           </div>
           <div className="listitem">
             <Link to="/heatmeter">
               <div><img src={heat}/></div>
               <p>热表</p>
             </Link>  
           </div>
           <div className="listitem" >
             <Link to="/gateway">
               <div><img src={gateway}/></div>
               <p>网关</p>
              </Link> 
           </div>
           <div className="listitem" >
             <Link to="/solution/software">
               <div><img src={other}/></div>
               <p>软件</p>
             </Link>
           </div>
        </div>
        
        <div className="producttitle">
           <div className="titleleft"></div>
           <div className="titletext">经典产品</div>
           <div className="titleright"></div>
        </div>

        <div className="classcalproduct">
            <div>
            <Link to="/watermeter/s663">
            <img src={WaterS663} style={{width:'100%'}}/>
            </Link>
            </div>
            <div>
            <Link to="/watermeter/mec">
            <img src={WaterMec} style={{width:'100%'}}/>
            </Link>
            </div>
            <div>
            <Link to="/watermeter/voha">
            <img src={WaterVoha} style={{width:'100%'}}/>
            </Link>
            </div>
            <div>
            <Link to="/elecmeter/dds5168b">
            <img src={ElecDds5168b} style={{width:'100%'}}/>
            </Link>
            </div>
            <div>
            <Link to="/elecmeter/ddsu5168">
            <img src={ElecDdsu5168} style={{width:'100%'}}/>
            </Link>
            </div>
            <div>
            <Link to="/elecmeter/dds5168_4p">
            <img src={ElecDds5168_4p} style={{width:'100%'}}/>
            </Link>
            </div>
            <div>
            <Link to="/elecmeter/dtsd5168">
            <img src={ElecDtsd5168} style={{width:'100%'}}/>
            </Link>
            </div>
            <div>
            <Link to="/heatmeter/r200">
            <img src={HeatR200} style={{width:'100%'}}/>
            </Link>
            </div>
            <div>
            <Link to="/heatmeter/h100">
            <img src={HeatH100} style={{width:'100%'}}/>
            </Link>
            </div>
            

            <div>
            <Link to="/gateway/gw200">
            <img src={GatewayGw200} style={{width:'100%'}}/>
            </Link>
            </div>
            <div>
            <Link to="/gateway/joytcp">
            <img src={GatewayJoytcp} style={{width:'100%'}}/>
            </Link>
            </div>
        </div>
      </div>
    );
  }
}

export default Product;