import React, { Component } from 'react';
import './index.scss';
import HeatR200 from '../../../../../assets/product/heatmeter/index/r200.png'
import HeatH100 from '../../../../../assets/product/heatmeter/index/h100.png'
import {Link} from 'react-router-dom'


class HeatIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='热表'
  }

  render() {
    return (
      <div>
        <div className="heatIndex">
              <div>
              <Link to="/heatmeter/r200">
              <img src={HeatR200}/>
              </Link>
              </div>
              <div>
              <Link to="/heatmeter/h100">
              <img src={HeatH100}/>
              </Link>
              </div>
        </div>
      </div>
    );
  }

}

export default HeatIndex;
