import React, { Component } from 'react';
import './index.scss';
import h100pic1 from '../../../../../assets/product/heatmeter/h100/h100-1.png'
import h100pic2 from '../../../../../assets/product/heatmeter/h100/h100-2.png'
import h100pic3 from '../../../../../assets/product/heatmeter/h100/h100-3.png'
import h100pic4 from '../../../../../assets/product/heatmeter/h100/h100-4.png'
import h100pic5 from '../../../../../assets/product/heatmeter/h100/h100-5.png'
import h100pic6 from '../../../../../assets/product/heatmeter/h100/h100-6.png'
import h100pic7 from '../../../../../assets/product/heatmeter/h100/h100-7.png'
import h100pic8 from '../../../../../assets/product/heatmeter/h100/h100-8.png'


class HeatH100 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){
    document.title='热表'
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={h100pic1}/>
          <img src={h100pic2}/>
          <img src={h100pic3}/>
          <img src={h100pic4}/>
          <img src={h100pic5}/>
          <img src={h100pic6}/>
          <img src={h100pic7}/>
          <img src={h100pic8}/>
      </div>
    );
  }

}

export default HeatH100;
