import React, { Component } from 'react';
import './index.scss';
import {Link } from "react-router-dom"
import homepic1 from '../../../assets/wxhome/home-1.png'
import homepic2 from '../../../assets/wxhome/home-2.png'
import homepic3 from '../../../assets/wxhome/home-3.png'
import homepic4 from '../../../assets/wxhome/home-4.png'
import homepic5 from '../../../assets/wxhome/home-5.png'
import homepic6 from '../../../assets/wxhome/home-6.png'
import product from '../../../assets/wxhome/product.png'
import solution from '../../../assets/wxhome/solution.png' 
import indexbg from  '../../../assets/wxhome/indexbg.png'

class WxHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='超仪科技股份有限公司'
  }



  render() {
    return (
      <div className="imageBlock">
          <img src={homepic1} />
          <img src={homepic2} />
            

             <div className="container">
               <img src={indexbg} />
               <div className="innerproduct"><Link to="/product"><img src={product} /></Link></div>
               <div className="innersolution"><Link to="/solution"><img src={solution} /></Link></div>
            </div>

          <img src={homepic3}/>
          <img src={homepic4} />    
          <img src={homepic5} />
          <img src={homepic6} />
      </div>
    );
  }
}

export default WxHome;
