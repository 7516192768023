import React, { Component } from 'react';
import './index.scss';
import education from '../../../../assets/app/job/education.png'
import experence from '../../../../assets/app/job/experence.png'
import location from '../../../../assets/app/job/location.png'



class JobDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs:{}
    };
  }
  
  
  componentDidMount(){
    document.title='招聘'
    let jobs = this.props.location.state.value;
    this.setState({
      jobs:jobs
    })
    this.setState({
      jobs:jobs
    })
  }


  render() {
    const {jobs} = this.state;
    return (
      <div className="titlecontent">
          <div className="titlefirst">
            <span className="jobtitleleft">{jobs.title}</span>
            <span className="jobtitleright">{jobs.salary}</span>
          </div>
          <div className="titlesecond">
             <img src={location}/>
             <span style={{marginLeft:6}}>{jobs.address}</span>

             <img style={{marginLeft:12}} src={experence}/>
             <span style={{marginLeft:6}}>{jobs.experence}</span>

             <img style={{marginLeft:12}} src={education}/>
             <span style={{marginLeft:6}}>{jobs.education}</span>
          </div>
          <div className="jobtitle">职位详情</div>
          <div>
            {
             jobs.jobdetail&&jobs.jobdetail.map((value) =>(
                 <div className="joblist">{value}</div>
              ))
            }
          </div>
          <div className="jobneed">【岗位要求】</div>
          <div>
            {
              jobs.jobrequire&&jobs.jobrequire.map((value) => (
                 <div className="joblist">{value}</div>
              ))
            }
          </div>

      </div> 
    );
  }

}

export default JobDetail;
