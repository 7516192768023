import React, { Component } from 'react';
import './index.scss';
import dds5168_4ppic1 from '../../../../../assets/product/electricmeter/dds5168_4p/dds5168_4p-1.png'
import dds5168_4ppic2 from '../../../../../assets/product/electricmeter/dds5168_4p/dds5168_4p-2.png'
import dds5168_4ppic3 from '../../../../../assets/product/electricmeter/dds5168_4p/dds5168_4p-3.png'
import dds5168_4ppic4 from '../../../../../assets/product/electricmeter/dds5168_4p/dds5168_4p-4.png'
import dds5168_4ppic5 from '../../../../../assets/product/electricmeter/dds5168_4p/dds5168_4p-5.png'
import dds5168_4ppic6 from '../../../../../assets/product/electricmeter/dds5168_4p/dds5168_4p-6.png'
import dds5168_4ppic7 from '../../../../../assets/product/electricmeter/dds5168_4p/dds5168_4p-7.png'
import {Link} from 'react-router-dom'



class ElectricDds5168_4p extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsmeter:''
    };
  }
  
  
  componentDidMount(){
    document.title='电表'
    setTimeout(()=> {
      this.setState({
        paramsmeter:'电能表参数>>'
      })
    },1000)
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={dds5168_4ppic1}/>
          <img src={dds5168_4ppic2}/>
          <img src={dds5168_4ppic3}/>
          <img src={dds5168_4ppic4}/>
          <img src={dds5168_4ppic5}/>
          <img src={dds5168_4ppic6}/>
          <div className="bottomLink-dds5168_4p">
          <img src={dds5168_4ppic7}/>
          <Link to='/elecmeter/para'><div>{this.state.paramsmeter}</div></Link>
          </div>
      </div>
    );
  }

}

export default ElectricDds5168_4p;
