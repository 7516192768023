import React, { Component } from 'react';
import './index.scss';
import cv151pic1 from '../../../../../assets/product/gateway/cv151/cv151-1.png'
import cv151pic2 from '../../../../../assets/product/gateway/cv151/cv151-2.png'
import cv151pic3 from '../../../../../assets/product/gateway/cv151/cv151-3.png'
import cv151pic4 from '../../../../../assets/product/gateway/cv151/cv151-4.png'
import cv151pic5 from '../../../../../assets/product/gateway/cv151/cv151-5.png'
import cv151pic6 from '../../../../../assets/product/gateway/cv151/cv151-6.png'
import cv151pic7 from '../../../../../assets/product/gateway/cv151/cv151-7.png'



class GatewayCV151 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='网关'
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={cv151pic1}/>
          <img src={cv151pic2}/>
          <img src={cv151pic3}/>
          <img src={cv151pic4}/>
          <img src={cv151pic5}/>
          <img src={cv151pic6}/>
          <img src={cv151pic7}/>
      </div>
    );
  }

}

export default GatewayCV151;
