import React, { Component } from 'react';
import { NavBar, Icon, List, Flex } from 'antd-mobile';
import { default as Popover } from './popover/index';
import { Link } from 'react-router-dom';
import { LabelMap } from './list';
import './index.scss';

class ProductDetail extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    document.title='产品详情';
  }

  render() {

    const { item } = this.props.location.state;
    if (item.productType) {
      window.sessionStorage.setItem("productType", item.productType);
    }
    
    let detail = [];
    Object.keys(item).map( key => {
      if (key != 'model' && key != 'productName' && key != 'productId' && key != 'productType' && key != 'categoryId' && item[key] && item[key] != '') {
        detail.push(key);    
      }
    });

    let children = [];
    for (let i = 0; i < detail.length; i = i + 2) {
      children.push(<List.Item key={`row_${i}`}>
                      <Flex>
                        <Flex.Item style={{  borderRight: '0.5px solid #f0f0f0' }}>
                          <span className="detail-title">{LabelMap[detail[i]]}</span>  
                          <List.Item.Brief>
                            {detail[i] == 'description' || detail[i] == 'internalModel'  || detail[i] == 'currentSpecification' ? (
                              <Popover content={item[detail[i]]} placement={'bottom'} mode="dark" trigger='click' destroyOnHide>
                                <span className="detail-data">{item[detail[i]]}</span>  
                              </Popover>
                            ) : (
                              <span className="detail-data">{item[detail[i]]}</span>  
                            )}                            
                          </List.Item.Brief>    
                        </Flex.Item>
                        <Flex.Item>
                          <span className="detail-title">{LabelMap[detail[i + 1]]}</span>  
                          <List.Item.Brief>
                            {detail[i + 1] == 'description' || detail[i + 1] == 'internalModel' || detail[i + 1] == 'currentSpecification' ? (
                              <Popover content={item[detail[i + 1]]} placement={'bottom'} mode="dark" trigger='click' destroyOnHide>
                                <span className="detail-data">{item[detail[i + 1]]}</span>  
                              </Popover>
                            ) : (
                              <span className="detail-data">{item[detail[i + 1]]}</span>  
                            )} 
                          </List.Item.Brief>    
                        </Flex.Item>
                      </Flex>  
                    </List.Item>)
    }

    return (<div key={item.productId}>
        {/* <NavBar           
            mode="light"
            icon={<Link to={{ pathname:'/product/guide', state: { ...this.props.location.state, productType: item.productType } }}>
                    <Icon type="left"  style={{ color: '#1677ff'}}/>
                  </Link>}
            // onLeftClick={() => console.log('onLeftClick')}
            rightContent={[
                <Icon key="1" type="ellipsis" />
            ]}
        >
          产品详情
        </NavBar> */}
        <List renderHeader={() => <div style={{ fontSize: 14, fontWeight: 600, padding: '6px 0px', color: 'black' }}>{item.model} {item.productName}</div>}>
          {children}
        </List>
    </div>);
  }
}

export default ProductDetail;