import React, { Component } from 'react';
import './index.scss';
import softwarepic1 from '../../../../assets/product/software/software-1.png'
import softwarepic2 from '../../../../assets/product/software/software-2.png'
import softwarepic3 from '../../../../assets/product/software/software-3.png'
import softwarepic4 from '../../../../assets/product/software/software-4.png'

import { ListView } from 'antd-mobile';


class Software extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='软件产品'
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={softwarepic1}/>
          <img src={softwarepic2}/>
          <img src={softwarepic3}/>
          <img src={softwarepic4}/>
      </div>
    );
  }

}

export default Software;
