import React, { Component } from 'react';
import './index.scss';
import {Link} from 'react-router-dom'
import banner from '../../../../assets/solution/home/banner.png'
import waterpic from '../../../../assets/solution/home/water.png'
import heatpic from '../../../../assets/solution/home/heat.png'
import elecpic from '../../../../assets/solution/home/elec.png'



class SolutionHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='方案中心'
  }

  render() {
    return (
      <div className="solutionHome" style={{background:'#EDEDED'}}>
           <img src={banner}/>
          <Link to="/solution/water">
           <div className="list" style={{width:'90%'}}>
               <img src={waterpic} />
           </div>
          </Link>
          <Link to="/solution/elec">
           <div className="list" style={{width:'90%'}}>
               <img src={elecpic} />
           </div>
          </Link>
          <Link to="/solution/heat">
           <div className="list" style={{width:'90%'}}>
               <img src={heatpic} />
           </div>
          </Link>
      </div>
    );
  }

}

export default SolutionHome;
