import React, { Component } from 'react';
import './index.scss';
import ElectricDds5168b from '../../../../../assets/product/electricmeter/index/dds5168b.png'
import ElectricDdsu5168 from '../../../../../assets/product/electricmeter/index/ddsu5168.png'
import ElectricDds5168_4p from '../../../../../assets/product/electricmeter/index/dds5168_4p.png'
import ElectricDtsd5168 from '../../../../../assets/product/electricmeter/index/dtsd5168.png'
import {Link} from 'react-router-dom'


class ElectricIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  
  componentDidMount(){
    document.title='电表'
  }

  render() {
    return (
        <div className="elecIndex">
              <div>
              <Link to="/elecmeter/dds5168b">
              <img src={ElectricDds5168b}/>
              </Link>
              </div>
            <div>
             <Link to="/elecmeter/ddsu5168">
               <img src={ElectricDdsu5168}/>
             </Link> 
            </div>
            <div>
             <Link to="/elecmeter/dds5168_4p">
               <img src={ElectricDds5168_4p}/>
             </Link>  
            </div>
            <div>
             <Link to="/elecmeter/dtsd5168">
               <img src={ElectricDtsd5168}/>
             </Link> 
            </div>
        </div>
    );
  }

}

export default ElectricIndex;
