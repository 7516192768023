import React, { Component } from 'react';
import './index.scss';
import parapic1 from '../../../../../assets/product/watermeter/para/para-1.png'
import parapic2 from '../../../../../assets/product/watermeter/para/para-2.png'



class WaterPara extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount(){
    document.title='水表参数'
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={parapic1}/>
          <img src={parapic2}/>
      </div>
    );
  }

}

export default WaterPara;
