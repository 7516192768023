import React, { Component } from 'react';
import './index.scss';
import dds5168bpic1 from '../../../../../assets/product/electricmeter/dds5168b/dds5168b-1.png'
import dds5168bpic2 from '../../../../../assets/product/electricmeter/dds5168b/dds5168b-2.png'
import dds5168bpic3 from '../../../../../assets/product/electricmeter/dds5168b/dds5168b-3.png'
import dds5168bpic4 from '../../../../../assets/product/electricmeter/dds5168b/dds5168b-4.png'
import dds5168bpic5 from '../../../../../assets/product/electricmeter/dds5168b/dds5168b-5.png'
import dds5168bpic6 from '../../../../../assets/product/electricmeter/dds5168b/dds5168b-6.png'
import dds5168bpic7 from '../../../../../assets/product/electricmeter/dds5168b/dds5168b-7.png'

import {Link} from 'react-router-dom'


class ElectricDds5168b extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsmeter:''
    };
  }
  
  componentDidMount(){
    document.title='电表'
    setTimeout(()=> {
      this.setState({
        paramsmeter:'电能表参数>>'
      })
    },1000)
  }

  render() {
    return (
      <div className="imageBlock">
          <img src={dds5168bpic1}/>
          <img src={dds5168bpic2}/>
          <img src={dds5168bpic3}/>
          <img src={dds5168bpic4}/>
          <img src={dds5168bpic5}/>
          <img src={dds5168bpic6}/>
          <div className="bottomLink-dds5168b">
             <img src={dds5168bpic7}/>
            <Link to='/elecmeter/para'><div>{this.state.paramsmeter}</div></Link>
          </div>
      </div>
    );
  }

}

export default ElectricDds5168b;
