
export const ItemList = [
    {
        label: '选项一',
        value: '1',
    },
    {
        label: '选项二',
        value: '2',
    },
    {
        label: '选项三',
        value: '3',
    },
    {
        label: '选项四',
        value: '4',
    },
]

export const CounterSoaking = [
    {
        label: '湿式',
        value: '湿式',
    },
    {
        label: '干式',
        value: '干式',
    },
    {
        label: '超声',
        value: '超声',
    }
]

export const Valve = [
    {
        label: '有阀',
        value: '有阀',
    },
    {
        label: '无阀',
        value: '无阀',
    },    
]

export const ColdHot = [
    {
        label: '冷',
        value: '冷',
    },
    {
        label: '热',
        value: '热',
    },
]

export const BaseMaterial = [
    {
        label: '不锈钢',
        value: '不锈钢',
    },
    {
        label: '铁',
        value: '铁',
    },
    {
        label: '铜',
        value: '铜',
    }
]

export const WaterCommMode = [
    {
        label: 'LoRa',
        value: 'LoRa',
    },
    {
        label: 'LoRaWAN',
        value: 'LoRaWAN',
    },
    {
        label: 'M-Bus',
        value: 'M-Bus',
    },
    {
        label: 'NB-IoT',
        value: 'NB-IoT',
    },
    {
        label: 'RF卡',
        value: 'RF卡',
    },
]

export const TemperatureRange = [
    {
        label: '（4~95）℃',
        value: '（4~95）℃',
    },
    {
        label: '（0~150）℃',
        value: '（0~150）℃',
    },    
]

export const HeatBaseMaterial = [
    {
        label: '碳钢',
        value: '碳钢',
    },
    {
        label: '铁',
        value: '铁',
    },
    {
        label: '铜',
        value: '铜',
    }
]

export const HeatCommMode = [    
    {
        label: 'M-Bus',
        value: 'M-Bus',
    },
    {
        label: 'NB-IoT',
        value: 'NB-IoT',
    },
]

export const Specification = [    
    {
        label: '5孔',
        value: '5孔',
    },
    {
        label: '单相',
        value: '单相',
    },
    {
        label: '三相',
        value: '三相',
    },
]

export const InstallType = [    
    {
        label: '壁挂',
        value: '壁挂',
    },
    {
        label: '导轨',
        value: '导轨',
    },
    {
        label: '柜式',
        value: '柜式',
    },    
]

export const RateType = [    
    {
        label: '单费率',
        value: '单费率',
    },
    {
        label: '多费率',
        value: '多费率',
    },
]

export const Induction = [    
    {
        label: '直接',
        value: '直接',
    },
    {
        label: '互感',
        value: '互感',
    },
]

export const Relay = [    
    {
        label: '是',
        value: 'Y',
    },
    {
        label: '否',
        value: 'N',
    },
]

export const CommAntenna = [    
    {
        label: '内置',
        value: '内置',
    },
    {
        label: '外置',
        value: '外置',
    },
]

export const EletricCommMode = [
    {
        label: '4G',
        value: '4G',
    },
    {
        label: 'GPRS',
        value: 'GPRS',
    },
    {
        label: 'LoRa',
        value: 'LoRa',
    },
    {
        label: 'LoRa+GPRS',
        value: 'LoRa+GPRS',
    },
    {
        label: 'LoRaWAN',
        value: 'LoRaWAN',
    },
    {
        label: 'NB-IoT',
        value: 'NB-IoT',
    },
    {
        label: 'RS485',
        value: 'RS485',
    },
    {
        label: 'WIFI',
        value: 'WIFI',
    },
    {
        label: '射频卡',
        value: '射频卡',
    },
]

export const CommDown = [    
    {
        label: 'LoRa',
        value: 'LoRa',
    },
    {
        label: 'LoRaWAN',
        value: 'LoRaWAN',
    },
    {
        label: 'M-Bus',
        value: 'M-Bus',
    },
    {
        label: 'RS485',
        value: 'RS485',
    },
    {
        label: 'RS485+MBUS',
        value: 'RS485+MBUS',
    },    
]

export const CommUp = [
    {
        label: '4G',
        value: '4G',
    },
    {
        label: 'GPRS',
        value: 'GPRS',
    },
    {
        label: 'GPRS+以太网',
        value: 'GPRS+以太网',
    },   
    {
        label: 'LoRaWAN',
        value: 'LoRaWAN',
    },
    {
        label: 'WIFI+以太网',
        value: 'WIFI+以太网',
    },
    {
        label: '以太网',
        value: '以太网',
    },    
]

export const Payload = [    
    {
        label: '水100 电100',
        value: '水100 电100',
    },
    {
        label: '水200 电100',
        value: '水200 电100',
    },
    {
        label: '电100',
        value: '电100',
    },
    {
        label: '热100',
        value: '热100',
    },
    {
        label: '热200',
        value: '热200',
    },
    {
        label: '信号覆盖范围内',
        value: '信号覆盖范围内',
    },
]

export const LabelMap = {
    partNo: '物料号',
    description: '品名',
    internalModel: '品号（内部型号）',
    counterType:  '计数器类型',
    counterSoaking:  '计数器浸水',
    coldHot:  '冷热',
    sensorType:  '传感类型',
    caliber:  '口径',
    measurePrinciple:  '测量原理',
    beamNumber:  '水流束数',
    baseMaterial:  '基表材质',
    platinumResistor: '铂电阻',
    temperatureRange: '温度范围',
    differenceRange: '温差范围',        
    maxPressure: '最大压力',  

    specification: '规格',    
    currentSpecification: '电流规格',    
    ratedVoltage: '额定电压',    
    frequency: '频率Hz',
    pulseConst: '脉冲常数 imp/kWh',
    relay: '继电器',
    induction: '直通/互感',
    rateType: '费率类型',

    commMode:  '通讯方式',
    commDown:  '下行通信',
    commUp:  '上行通信',    
    supportedDevice:  '支持设备类型',    
    payload:  '带载量',
    supplyMode:  '供电方式',

    installSite: '安装环境',
    installType:  '安装方式',

    valve:  '阀控',
    temperatureGrade:  '温度等级',
    precision:  '精度',
    ipGrade:  'IP等级',
    produceLife:  '产品寿命',
    battery:  '电池更换',
    hardwarePlatform:  '硬件平台',
    productOrigin:  '产品来源',
    oemBrand:  'OEM品牌',
    status:  '状态',
    scene:  '适用场景',
    remark: '备注',    
    remark2: '备注2',

    factoryPrice: '出厂价',
    marketPrice: '市场价',
    channelPrice: '渠道价',
    suggestedPrice: '公寓事业部销售建议价',
}